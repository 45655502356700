const apiUrl = {
  Development: "https://api.test.tongucmagaza.com/api",
  //Development: "https://tm-api.dev.tongucworks.com/api",
  Test: "https://api.test.tongucmagaza.com/api",
  Production: "https://api.tongucmagaza.com/api",
  Cloud: "https://cloud-dkapi.tongucmagaza.com/api",
};
export const tatsApiPosUrl = {
  // Development: "https://localhost:44306/api",
  Development: "https://tats-ec.test.tongucworks.com/api",
  Test: "https://tats-ec.test.tongucworks.com/api",
  Production: "https://tats-ec.synapsis.com.tr/api",
};

const tatsApiUrl = {
  Development: "https://tatsapi.test.tongucworks.com/api",
  //Development: "https://mobilservisv2.tats.test.tongucbilisim.com/api",
  //Development: "https://localhost:44330/api",
  Test: "https://tatsapi.test.tongucworks.com/api",
  Production: "https://tatsapi.synapsis.com.tr/api",
  Cloud: "https://cloud-tatsapi.dogrukaynak.com/api",
};

const tongucSinavdaUrl = {
  Development: "https://sinav.test.dogrukaynak.com/",
  Test: "https://sinav.test.dogrukaynak.com/",
  Production: "https://sinav.dogrukaynak.com/",
  Cloud: "https://sinav.dogrukaynak.com/",
};

const tongucAkademiUrl = {
  Development: "https://test.tongucakademi.com/",
  Test: "https://test.tongucakademi.com/",
  Production: "https://www.tongucakademi.com/",
  Cloud: "https://www.tongucakademi.com/",
};

const S3BucketUrl = {
  Development: "https://cdn.test.tongucmagaza.com",
  Test: "https://cdn.test.tongucmagaza.com",
  Production: "https://cdn.tongucmagaza.com",
  Cloud: "https://cdn.tongucmagaza.com",
};

const S3ContractBucketUrl = {
  Development: "https://contracts.test.tongucworks.com",
  Test: "https://contracts.test.tongucworks.com",
  Production: "https://contracts.synapsis.com.tr",
};

export const googleAnalytics = {
  trackingId: "UA-94050230-1",
  awId: "AW-596855146",
};

export const keys = {
  Token: "STOREX__JWT",
  Refresh: "STOREX__REFRESH",
  User: "STOREX__USER",
  Basket: "STOREX__BASKET",
  BasketCount: "STOREX__BASKET__COUNT",
  CookiePolicy: "STOREX__COOKIE__POLICY",
  LastLocation: "STOREX__LAST__LOCATION",
  MailVerificationRedirectLocation:
    "STOREX__MAIL__VERIFICATION__LAST__LOCATION",
  LastUserId: "STOREX__LAST__USER__ID",
  LastOrderCode: "STOREX__LAST__ORDER__CODE",
  WaitingForEmailVerification: "STOREX__WAITING_FOR_EMAIL_VERIFICATION",
};

export const settings = {
  GET_DATA_FROM_AWS: true,
  showTopicsInBookDetail: false,
  GetProductsFromApi: false,
};

export const turnstileSiteKeys = {
  Payment: "0x4AAAAAAANKTSXwO3TEknSu",
  Login: "0x4AAAAAAAYp-L1w_evhtVgQ",
  Register: "0x4AAAAAAAYqDGDuTfnKkQxJ",
};

// export const GoogleMapsAPIKey = "";

// export const GoogleMapsImage = (
//   address,
//   size = "600x300",
//   zoom = 13,
//   maptype = "roadmap"
// ) => {
//   const googleURLPrefix =
//     "https://maps.googleapis.com/maps/api/staticmap?center=";
//   return `${googleURLPrefix}${encodeURIComponent(
//     address
//   )}&size=${size}&zoom=${zoom}&maptype=${maptype}&key=${GoogleMapsAPIKey}`;
// };

export const GoogleMapsSearch = (address) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const paymentType = {
  CreditCard: 1,
  BankTransfer: 2,
  DoorPayment: 3,
  Coin: 4,
  BkmExpress: 5,
};

export const favoriteTypes = {
  product: 1,
  productDetail: 2,
  brand: 3,
};

export const defaultValues = {
  BASKET_KEY: "00000000-0000-0000-0000-000000000000",
};

export const reservationStatus = {
  Canceled: 0,
  TimedOut: 1,
  Waiting: 2,
  Completed: 3,
};

export const orderList = [
  {
    value: "order",
    label: "Akıllı Sıralama",
  },
  {
    value: "newest",
    label: "En Yeniler",
  },
  {
    value: "class-increasing",
    label: "Sınıf 1’den 12’ye",
  },
  {
    value: "class-decreasing",
    label: "Sınıf 12’den 1’e",
  },
  {
    value: "price-increasing",
    label: "En Düşük Fiyat",
  },
  {
    value: "price-decreasing",
    label: "En Yüksek Fiyat",
  },
];

const mfsAddress = {
  Development: "https://ui.masterpassturkiye.com/v2",
  Test: "https://ui.masterpassturkiye.com/v2",
  Production: "https://ui.masterpassturkiye.com/v2",
  Cloud: "https://ui.masterpassturkiye.com/v2",
};

export const MFS = {
  address: mfsAddress[process.env.REACT_APP_NODE_ENV] || mfsAddress.Development,
  clientId: "34706112",
};

const videoInteractionSettings = {
  Development: {
    ApiUrl: "https://api.test.tongucworks.com/video-interaction/api",
    ScriptUrl:
      "https://cdn.synapsis.com.tr/VideoInteraction/synapsis-interactions-test.js",
    SecondsForMarkAsViewed: 10,
  },
  Test: {
    ApiUrl: "https://api.test.tongucworks.com/video-interaction/api",
    ScriptUrl:
      "https://cdn.synapsis.com.tr/VideoInteraction/synapsis-interactions-test.js",
    SecondsForMarkAsViewed: 10,
  },
  Production: {
    ApiUrl: "https://api.synapsis.com.tr/video-interaction/api",
    ScriptUrl:
      "https://cdn.synapsis.com.tr/VideoInteraction/synapsis-interactions.js",
    SecondsForMarkAsViewed: 10,
  },
  Cloud: {
    ApiUrl: "https://api.synapsis.com.tr/video-interaction/api",
    ScriptUrl:
      "https://cdn.synapsis.com.tr/VideoInteraction/synapsis-interactions.js",
    SecondsForMarkAsViewed: 10,
  },
};

export const videoInteractionSetting =
  videoInteractionSettings[process.env.REACT_APP_NODE_ENV] ||
  videoInteractionSettings.Development;

export const endPoint =
  apiUrl[process.env.REACT_APP_NODE_ENV] || apiUrl.Development;
export const tatsEndPoint =
  tatsApiUrl[process.env.REACT_APP_NODE_ENV] || tatsApiUrl.Development;
export const S3BucketEndPoint =
  S3BucketUrl[process.env.REACT_APP_NODE_ENV] || S3BucketUrl.Development;
export const S3ContractBucketEndPoint =
  S3ContractBucketUrl[process.env.REACT_APP_NODE_ENV] ||
  S3ContractBucketUrl.Development;
export const tongucSinavdaEndpoint =
  tongucSinavdaUrl[process.env.REACT_APP_NODE_ENV] ||
  tongucSinavdaUrl.Development;
export const tongucAkademiEndpoint =
  tongucAkademiUrl[process.env.REACT_APP_NODE_ENV] ||
  tongucAkademiUrl.Development;

export const creditCartMonths = [
  {
    value: "01",
    label: "01",
  },
  {
    value: "02",
    label: "02",
  },
  {
    value: "03",
    label: "03",
  },
  {
    value: "04",
    label: "04",
  },
  {
    value: "05",
    label: "05",
  },
  {
    value: "06",
    label: "06",
  },
  {
    value: "07",
    label: "07",
  },
  {
    value: "08",
    label: "08",
  },
  {
    value: "09",
    label: "09",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
];
const generateCreditCardExpireYears = (count) => {
  const years = [];
  const currentYear = new Date().getFullYear() % 100;
  for (let i = 0; i < count; i++) {
    years.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }
  return years;
}
export const creditCartYears = generateCreditCardExpireYears(20);


export const queryStringParameters = [
  {
    key: "marka",
    type: "brand",
    isDefault: false,
  },
  {
    key: "b",
    type: "brand",
    isDefault: true,
  },
  {
    key: "t",
    type: "taxon",
    isDefault: true,
    parentCode: null,
  },
  {
    key: "seri",
    type: "taxon",
    isDefault: false,
    parentCode: "kitap-serileri",
  },
  {
    key: "sinif",
    type: "taxon",
    isDefault: false,
    parentCode: "siniflar",
  },
  {
    key: "sinav",
    type: "taxon",
    isDefault: false,
    parentCode: "sinavlar",
  },
  {
    key: "ders",
    type: "taxon",
    isDefault: false,
    parentCode: "dersler",
  },
  {
    key: "tip",
    type: "taxon",
    isDefault: false,
    parentCode: "urun-tipi",
  },
  {
    key: "kategori",
    type: "taxon",
    isDefault: false,
    parentCode: "kategoriler",
  },
  {
    key: "k",
    type: "keyword",
    isDefault: true,
  },
  {
    key: "sort",
    type: "sort",
    isDefault: true,
  },
  // {
  //   key: "i",
  //   type: "index",
  //   isDefault: false,
  // },
];

export const pilotTowns = [1183];

export const footerIcons = [
  {
    id: 1,
    icon: require("../assets/images/footer/facebook.svg"),
    name: "Facebook",
    link: "https://www.facebook.com/dogrukaynak/",
  },
  //{
  //    id: 2,
  //    icon: require('../assets/images/footer/twitter.svg'),
  //    name: 'Twitter',
  //    link: 'https://twitter.com/kaynak_dogru'
  //},
  {
    id: 3,
    icon: require("../assets/images/footer/instagram.svg"),
    name: "Instagram",
    link: "https://www.instagram.com/dogru.kaynak/",
  },
  {
    id: 4,
    icon: require("../assets/images/footer/youtube.svg"),
    name: "Youtube",
    link: "https://www.youtube.com/channel/UC0q0cAT7a6xhxxAYzYYtM0A/featured",
  },
];

export const productDetailSuffixPhrase2 = " - Tonguç Mağaza";
export const productDetailSuffixPhrase =
  " uygun fiyat, taksit ve kapıda ödeme seçenekleri ile Tonguç Mağaza’dan hemen satın alabilirsiniz.";

export const MaxReviewFileSizeMB = 500; // MB
export const MaxReviewFileSize = 500 * 1024 * 1024; // MB
