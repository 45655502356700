import React from "react";
import { MFS as constants } from "../../services/constants";
import { connect } from "react-redux";
import Modal from "../../components/Modal/Index";
import CloseButton from "../../components/Button/Close";
import { BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import { toast } from "react-toastify";
import Countdown from "react-countdown-now";
import mp from "../../assets/images/mp.png";
import { endPoint } from "../../services/constants";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreditCard from "../../views/Payment/CreditCard";
import { replaceDotWithComma } from "../../helpers";

const processList = {
  none: "",
  link: "link",
  registration: "registration",
  update: "update",
};

const otpType = {
  none: "",
  phone: "5008",
  bank: "5001",
};

const paymentType = {
  masterpass: "masterpass",
  creditCard: "creditCard",
};

const type3D = {
  none: "none",
  masterpass: "masterpass",
  creditCard: "creditCard",
};

class Masterpass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      status: {
        endUserHasCustomerCreditCard: 0,
        endUserHasMasterpassAccount: 0,
        endUserHasMasterpassCreditCard: 0,
        endUserHasRelationWithCompany: 0,
        endUserMassterpassAccountLocked: 0,
        endUserPhoneUpdated: 0,
      },
      process: processList.none,
      otpModalShow: false,
      otpType: otpType.none,
      registrationModalShow: false,
      listAccountName: "",
      binNumber: "",
      processing: false,
      renderer: false,
      isOTPInvalid: false,
      bankName: null,
      paymentType: paymentType.masterpass,
      type3D: type3D.none,
    };

    this.MFS = {};
    this.$ = {};
    this.token = null;
    this.saveToMasterpassCheck = React.createRef();
  }

  userHasMobileNumber = () => {
    let { mobile } = this.props.user;
    return mobile && mobile !== "" && mobile.replace(/ /g, "").length === 11;
  };

  async componentDidMount() {
    if (!this.userHasMobileNumber()) {
      return;
    }

    await this.props.getMasterpassTokens(this.getMasterpassTokenParams());

    if (!window.MFS) {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `${process.env.PUBLIC_URL}/mfs-client.min.js`;
      const x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);

      s.addEventListener("load", (e) => {
        this.onMFSLoad();
      });
    } else {
      this.onMFSLoad();
    }
  }

  setPaymentType = (e, type) => {
    e.preventDefault();

    if (type === this.state.paymentType) {
      return;
    }

    let state = { paymentType: type, type3D: type3D.none };

    if (type === paymentType.creditCard) {
      state.listAccountName = "";
      state.binNumber = "";
    }

    this.setState(state, () => {
      if (this.props.isSecure) {
        this.props.toggle3D();
      }
    });
  };

  otpModalClose = () => {
    this.setState({ otpModalShow: false, otpType: otpType.none }, () => {
      if (this.state.process === processList.registration) {
        this.props.mpRegistrationCompleted();
      }
    });
  };

  registrationModalClose = () => {
    this.setState({ registrationModalShow: false }, () => {
      this.props.mpRegistrationCompleted();
    });
  };

  checkMasterpass = async (regenerateToken = false) => {
    if (regenerateToken) {
      await this.props.getMasterpassTokens(this.getMasterpassTokenParams());
    }

    this.MFS.checkMasterPass(
      this.$("#checkMP-form"),
      this.chkMPResponseHandler
    );
  };

  chkMPResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      const [q, w, e, r, t, y] = response.accountStatus.split("");
      const status = {
        endUserHasCustomerCreditCard: q === "1",
        endUserHasMasterpassAccount: w === "1",
        endUserHasMasterpassCreditCard: e === "1",
        endUserHasRelationWithCompany: r === "1",
        endUserMassterpassAccountLocked: t === "1",
        endUserPhoneUpdated: y === "1",
      };
      if (
        status.endUserHasMasterpassAccount &&
        status.endUserHasMasterpassCreditCard &&
        status.endUserHasRelationWithCompany
      ) {
        this.listCards("9" + this.props.user.mobile.replace(/ /g, ""));
        this.setState({ status, paymentType: paymentType.masterpass });
      } else {
        this.props.handleCreditCardChange({});
        this.setState({ status, paymentType: paymentType.creditCard });
      }
    } else {
      toast.error(response.responseDescription);
    }
  };

  onMFSLoad = () => {
    this.$ = window.jQuery;
    this.MFS = window.MFS;
    this.MFS.setClientId(constants.clientId);
    this.MFS.setAddress(constants.address);

    this.checkMasterpass();
  };

  listCards = async (msisdn, regenerateToken = false) => {
    if (regenerateToken) {
      await this.props.getMasterpassTokens(this.getMasterpassTokenParams());
    }

    this.MFS.listCards(
      msisdn,
      this.props.masterpassTokens.token,
      this.listCardsResponseHandler
    );
  };

  listCardsResponseHandler = (statusCode, response) => {
    if (response.responseCode === "1051") {
      //There is no account owned by this phone number.
      this.setState({ cards: [] });
      return;
    } else if (response.responseCode != "0000" && response.responseCode != "") {
      toast.error(response.responseDescription);
      this.setState({ cards: [] });
      return;
    }

    this.setState({ cards: response.cards });
  };

  linkCards = (e) => {
    e.preventDefault();

    this.setState({ otpModalShow: true, process: processList.link }, () => {
      this.MFS.linkCardToClient(
        this.$("#linkCardtoMP-form"),
        this.mfsResponseHandler
      );
    });
  };

  updateUser = async () => {
    let { id, mobile, isMobileVerified, masterpassNumber } = this.props.user;
    mobile = mobile.replace(/ /g, "");
    const data = {
      msisdn: masterpassNumber,
      userId: id,
      validationType: !!this.props.isSecure ? 4 : 0,
      validated: isMobileVerified,
      bin:
        this.props.payment && this.props.payment.creditCardNumber
          ? this.props.payment.creditCardNumber
          : this.state.binNumber,
    };

    await this.props.getMasterpassTokens(data);
    this.setState({ process: processList.update }, () => {
      this.MFS.updateUser(
        this.$("#updateUser-form"),
        this.updateUserResponseHandler
      );
    });
  };

  updateUserResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      this.setState(
        {
          otpModalShow: false,
          otpType: otpType.none,
          process: processList.none,
          isOTPInvalid: false,
        },
        () => {
          toast.success("Telefon numaranız güncellendi.");

          this.checkMasterpass(true);
          this.setState({
            registrationModalShow: true,
            bankName: this.props.bankName,
          });
        }
      );
    } else {
      if (response.responseCode == "5001") {
        this.setState({
          otpModalShow: true,
          otpType: otpType.bank,
          process: processList.update,
          isOTPInvalid: false,
        });
      } else if (response.responseCode == "5008") {
        this.setState({
          otpModalShow: true,
          otpType: otpType.phone,
          process: processList.update,
          isOTPInvalid: false,
        });
      } else {
        toast.error(response.responseDescription);
      }
    }
  };

  mfsResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      this.setState(
        {
          otpModalShow: false,
          process: processList.none,
          otpType: otpType.none,
          isOTPInvalid: false,
        },
        () => {
          toast.success("Kartlarınız başarıyla eklendi.");
          this.checkMasterpass();
        }
      );
    } else {
      if (response.responseCode == "5001") {
        this.setState({
          otpModalShow: true,
          otpType: otpType.bank,
          isOTPInvalid: false,
        });
      } else if (response.responseCode == "5008") {
        this.setState({
          otpModalShow: true,
          otpType: otpType.phone,
          isOTPInvalid: false,
        });
      } else if (response.responseCode == "1409") {
        this.setState({ isOTPInvalid: true });
      } else {
        toast.error(response.responseDescription);
      }
    }
  };

  validateOTP = (data, action) => {
    if (action === processList.link) {
      this.MFS.validateTransaction(
        this.$("#otp-modal form"),
        this.mfsResponseHandler
      );
    } else if (action === processList.registration) {
      this.MFS.validateTransaction(
        this.$("#otp-modal form"),
        this.completeRegistrationHandler
      );
    } else if (action === processList.update) {
      this.MFS.validateTransaction(
        this.$("#otp-modal form"),
        this.updateUserResponseHandler
      );
    }
  };

  deleteCard = (e) => {
    e.preventDefault();
    const confirmed = window.confirm(
      "Kartınızı silmek istediğinize emin misiniz?"
    );
    if (confirmed) {
      this.MFS.deleteCard(
        this.$("#card-list-form"),
        this.deleteCardResponseHandler
      );
    }
  };

  deleteCardResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      toast.success("Kartınız başarıyla silindi.");
      this.checkMasterpass();
    }
  };

  acceptSave = (e) => {
    this.props.acceptSave(e.target.checked);
  };

  directPurchase = async (creditCardNumber) => {
    await this.props.getMasterpassTokens(
      this.getMasterpassTokenParams(creditCardNumber)
    );
    await this.MFS.directPurchase(
      this.$("#payment-form"),
      this.directPurchaseResponseHandler
    );
  };

  directPurchaseResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      // Purchase Success
      //$('#payment-form, #otp-form, #mpin-form').hide();
      //$("#complete-form").show();
      this.token = response.token;
      this.props.payAndSaveMasterpass(this.getMasterpassData(response.token));
    } else {
      //$('#payment-form, #otp-form, #mpin-form').hide();
      if (response.responseCode == "5001") {
        //$('#otp-form').show();
      } else if (response.responseCode == "5002") {
        //$('#mpin-form').show();
      } else if (response.responseCode == "5010") {
        // Ask 3D Secure
        this.token = response.token;
        this.props.initiate3D(
          `${response.url3D}&returnUrl=${endPoint}/payment/masterpass3DAndSave`,
          ""
        );
      } else if (response.responseCode == "5015") {
        //$('#mpin-form').show();
        //$('#mpin-define-label').show();
      } else {
        this.props.cancelMpProcess();
        toast.error(response.responseDescription);
      }
    }
  };

  completeRegistration = async () => {
    this.setState({ registrationModalShow: false }, async () => {
      await this.MFS.completeRegistration(
        this.$("#registration-modal"),
        this.token,
        this.completeRegistrationHandler
      );
    });
  };

  completeRegistrationHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      // Register Success
      this.setState(
        { otpModalShow: false, otpType: otpType.none, isOTPInvalid: false },
        () => {
          toast.success("Kartınız başarıyla kaydedildi.");
          this.props.mpRegistrationCompleted();
        }
      );
    } else {
      if (response.responseCode == "5001") {
        this.setState({
          otpModalShow: true,
          process: processList.registration,
          otpType: otpType.bank,
          isOTPInvalid: false,
        });
      } else if (response.responseCode == "5008") {
        this.setState({
          otpModalShow: true,
          process: processList.registration,
          otpType: otpType.phone,
          isOTPInvalid: false,
        });
      } else if (response.responseCode == "5002") {
        //$('#mpin-form').show();
        toast.warn("completeRegistrationHandler function code 5002");
      } else if (response.responseCode == "5010") {
        // Ask 3D Secure
        // window.location.assign(response.url3D + "&returnUrl=" + "Your Return URL");
        toast.warn("completeRegistrationHandler function code 5010");
      } else if (response.responseCode == "5015") {
        //$('#mpin-form').show();
        //$('#mpin-define-label').show();
        toast.warn("completeRegistrationHandler function code 5015");
      } else if (response.responseCode == "5000") {
        //$('#rta-form').show();
        toast.warn("completeRegistrationHandler function code 5000");
      } else if (response.responseCode == "1409") {
        this.setState({ isOTPInvalid: true });
      } else if (response.responseCode == "1080") {
        this.updateUser();
      } else if (response.responseCode == "5196") {
        toast.error(response.responseDescription);
        this.props.mpRegistrationCompleted();
      } else {
        toast.error(response.responseDescription);
      }
    }
  };

  getMasterpassTokenParams = (creditCardNumber) => {
    let { id, mobile, isMobileVerified } = this.props.user;
    mobile = mobile.replace(/ /g, "");
    const data = {
      msisdn: "9" + mobile,
      userId: id,
      validationType: !!this.props.isSecure ? 4 : 0,
      validated: isMobileVerified,
      bin: creditCardNumber ? creditCardNumber : this.state.binNumber,
    };

    return data;
  };

  getMasterpassData = (tokenValue) => {
    let { msisdn, refNumber, token } = this.props.masterpassTokens;
    const MasterpassData = {
      Msisdn: msisdn,
      UserId: constants.clientId,
      ClientToken: token,
      ReferenceNo: refNumber,
      Token: tokenValue,
    };
    return MasterpassData;
  };

  purchaseMasterpass = (event, card) => {
    //checkInstallment
    this.props.handleCreditCardChange({
      creditCardNumber: event.target.checked ? card.Value1 : "",
    });
    const state = event.target.checked
      ? {
          listAccountName: card.Name,
          binNumber: card.Value1,
          paymentType: paymentType.masterpass,
        }
      : { listAccountName: "", binNumber: "" };

    this.setState(
      state,
      ((event, card) => {
        // let tokenParams = this.getMasterpassTokenParams();
        // tokenParams.bin = event.target.checked ? card.Value1 : "";
        // this.props.getMasterpassTokens(tokenParams);
        this.props.purchaseMasterpassStateChange(
          event.target.checked,
          card.Value1
        );
      })(event, card)
    );
  };

  purchase3D = async (token3D, isSave) => {
    if (isSave) {
      this.props.payAndSaveMasterpass(this.getMasterpassData(token3D));
    } else {
      this.props.payWithMasterpass(this.getMasterpassData(token3D));
    }
  };

  purchase = async (creditCardNumber) => {
    await this.props.getMasterpassTokens(
      this.getMasterpassTokenParams(creditCardNumber)
    );
    await this.MFS.purchase(
      this.$("#purchase-form"),
      this.purchaseResponseHandler
    );
  };

  purchaseResponseHandler = (status, response) => {
    if (response.responseCode == "0000" || response.responseCode == "") {
      // Purchase Success
      this.token = response.token;
      this.props.payWithMasterpass(this.getMasterpassData(response.token));
    } else {
      // $('#list-form, #purchase-form, #otp-form, #mpin-form, #cvv-form').hide();
      if (response.responseCode == "5001") {
        //$('#otp-form').show();
        alert("otp-show");
      } else if (response.responseCode == "5002") {
        //$('#mpin-form').show();
        alert("mpin-show");
      } else if (response.responseCode == "5010") {
        // Ask 3D Secure

        this.props.initiate3D(
          `${response.url3D}&returnURL=${endPoint}/payment/masterpass3D`,
          response.token
        );
      } else if (response.responseCode == "5013") {
        //$('#cvv-form').show();
        alert("cvv");
      } else {
        toast.error(response.responseDescription);
      }
    }
  };

  async componentDidUpdate(prevProps) {
    if (this.props.isSecure != prevProps.isSecure) {
      //3D Secure Option has changed
      //await this.props.getMasterpassTokens(this.getMasterpassTokenParams());
    } else if (
      this.props.secure3DToken != prevProps.secure3DToken &&
      this.props.secure3DToken !== ""
    ) {
      this.purchase3D(this.props.secure3DToken, this.props.saveAfter3D);
    } else if (
      this.props.payment &&
      this.props.payment.purchaseMasterpass &&
      !this.props.payment.saveMasterpass &&
      !this.state.processing
    ) {
      this.setState({ processing: true }, () => {
        this.purchase(this.props.payment.creditCardNumber);
      });
    } else if (
      this.props.payment &&
      this.props.payment.saveMasterpass &&
      !this.props.payment.purchaseMasterpass &&
      !this.state.processing
    ) {
      this.setState({ processing: true }, () => {
        this.directPurchase(this.props.payment.creditCardNumber);
      });
    } else if (
      prevProps.startMPRegistration === false &&
      this.props.startMPRegistration
    ) {
      this.setState({
        registrationModalShow: true,
        bankName: this.props.bankName,
      });
    } else if (
      prevProps.payment &&
      this.props.payment === null &&
      this.state.processing
    ) {
      this.setState({ processing: false });
    }
  }

  openMP = (e) => {
    e.preventDefault();
    window.open("https://www.masterpassturkiye.com/TermsAndConditions.aspx");
  };

  resendOTP = (e) => {
    e.preventDefault();
    this.setState({ renderer: !this.state.renderer }, () => {
      const token = this.MFS.getLastToken();
      this.MFS.resendOtp(token, "tur", () => {});
    });
  };

  toggle3D = (e, type) => {
    let state = { type3D: type };

    let checked = e.target.checked;
    // if (this.props.isDigitalCoin && !checked) {
    //   toast.error("Jeton paketi alımlarında 3D kullanılması zorunludur");
    //   return;
    // }
    if (
      this.state.paymentType === paymentType.creditCard &&
      type === type3D.masterpass
    ) {
      state.paymentType = paymentType.masterpass;
    }

    this.setState(state, () => {
      this.props.toggle3D(checked);
    });
  };

  render() {
    let { msisdn, refNumber, token } = this.props.masterpassTokens;
    msisdn = msisdn.replace(/ /g, "");

    const { mobile } = this.props.user;
    const userMobile =
      mobile && mobile !== "" && mobile.replace(/ /g, "").length === 11
        ? "9" + mobile.replace(/ /g, "")
        : null;

    const countdownRenderer = ({ minutes, seconds, completed }) => {
      if (completed) {
        return (
          <button
            onClick={this.resendOTP}
            type="button"
            className="btn btn-primary btn-block py-2 fs-13 border-radius-8"
          >
            Tekrar Gönder
          </button>
        );
      } else {
        return (
          <span className="fs-14 text-decoration-none font-weight-500">
            {minutes}:{seconds}
          </span>
        );
      }
    };

    return (
      <>
        {this.userHasMobileNumber() ? (
          <>
            <form action="" method="POST" id="checkMP-form">
              <input type="hidden" name="msisdn" value={msisdn} />
              <input type="hidden" name="userId" value={msisdn} />
              <input type="hidden" name="token" value={token} />
              <input type="hidden" name="referenceNo" value={refNumber} />
              <input type="hidden" name="sendSmsLanguage" value="tur" />
              <input type="hidden" name="sendSms" value="N" />
            </form>

            <form action="" method="POST" id="linkCardtoMP-form">
              <input type="hidden" name="msisdn" value={msisdn} />
              <input type="hidden" name="token" value={token} />
              <input type="hidden" name="referenceNo" value={refNumber} />
              <input type="hidden" name="sendSmsLanguage" value="tur" />
              <input type="hidden" name="sendSms" value="N" />
            </form>

            <form action="" method="POST" id="delete-card">
              <input type="hidden" name="token" value={token} />
              <input type="hidden" name="referenceNo" value={refNumber} />
              <input type="hidden" name="sendSmsLanguage" value="tur" />
              <input type="hidden" name="sendSms" value="N" />
            </form>

            {this.props.payment ? (
              <form action="" method="POST" id="payment-form">
                <input
                  type="hidden"
                  name="rtaPan"
                  value={this.props.payment.creditCardNumber}
                />
                <input
                  type="hidden"
                  name="expiryDate"
                  value={`${this.props.payment.expireYear}${this.props.payment.expireMonth}`}
                />
                <input
                  type="hidden"
                  name="cvc"
                  value={this.props.payment.CVC}
                />
                <input
                  type="hidden"
                  name="cardHolderName"
                  value={this.props.payment.name}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={this.props.payment.total}
                />
                <input type="hidden" name="msisdn" value={msisdn} />
                <input type="hidden" name="token" value={token} />
                <input type="hidden" name="referenceNo" value={refNumber} />
                <input type="hidden" name="sendSmsLanguage" value="tur" />
                {/* <input type="hidden" name="macroMerchantId" value="0"/> */}
                <input
                  type="hidden"
                  name="orderNo"
                  value={this.props.payment.orderNumber}
                />
                <input type="hidden" name="sendSms" value="N" />
                {/* <input type="hidden" name="actionType" value="A" /> */}
                <input
                  type="hidden"
                  name="installmentCount"
                  value={this.props.installment}
                />
                {/* 
                    <input type="hidden" name="rewardName" value="BONUS" />
                    <input type="hidden" name="rewardValue" value="0" /> */}
              </form>
            ) : null}

            {this.props.payment ? (
              <form action="" method="POST" id="purchase-form">
                <input
                  type="hidden"
                  name="listAccountName"
                  value={this.state.listAccountName}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={this.props.payment.total}
                />
                <input type="hidden" name="token" value={token} />
                <input type="hidden" name="referenceNo" value={refNumber} />
                <input
                  type="hidden"
                  name="orderNo"
                  value={this.props.payment.orderNumber}
                />
                <input type="hidden" name="msisdn" value={msisdn} />
                {/* <input type="hidden" name="macroMerchantId" value="0" /> */}
                <input
                  type="hidden"
                  name="installmentCount"
                  value={this.props.installment}
                />
                {/* 
                    <input type="hidden" name="rewardName" value="BONUS" />
                    <input type="hidden" name="rewardValue" value="0" />
                    <input type="hidden" name="cvc" value="123" /> */}
                <input type="hidden" name="sendSmsLanguage" value="tur" />
                <input type="hidden" name="sendSms" value="N" />
                <input type="hidden" name="aav" value="aav" />
                <input type="hidden" name="clientIp" value="" />
                <input type="hidden" name="encCPin" value="0" />
                <input type="hidden" name="encPassword" value="" />
                <input type="hidden" name="sendSmsMerchant" value="Y" />
                <input type="hidden" name="password" value="" />
                <input type="hidden" name="pinType" value="3d" />
              </form>
            ) : null}

            <form action="" method="POST" id="updateUser-form">
              <input
                type="hidden"
                name="oldValue"
                value={this.props.user.masterpassNumber}
              />
              <input type="hidden" name="theNewValue" value={userMobile} />
              <input type="hidden" name="valueType" value="MSISDN" />{" "}
              {/* MSISDN or USER_ID */}
              <input
                type="hidden"
                name="msisdn"
                value={this.props.user.masterpassNumber}
              />
              <input type="hidden" name="token" value={token} />
              <input type="hidden" name="referenceNo" value={refNumber} />
              <input type="hidden" name="sendSmsLanguage" value="tur" />
              <input type="hidden" name="sendSms" value="N" />
            </form>

            <Modal
              id="registration-modal"
              className="modal-container non-modal-height registration-modal"
              show={this.state.registrationModalShow}
            >
              <div className="modal-header">
                <div className="modal-header-mp">
                  <h5>Kart Kayıt</h5>
                  <img src={mp} alt="MasterPass" />
                </div>
                <CloseButton onClick={this.registrationModalClose} />
              </div>
              <div className="modal-body">
                <BaseForm
                  schema={FormSchemas.mpRegistration.schema}
                  uiSchema={FormSchemas.mpRegistration.uiSchema}
                  formData={{
                    cardAliasName: this.state.bankName,
                    msisdn: msisdn,
                    token: token,
                    referenceNo: refNumber,
                    sendSms: "N",
                    sendSmsLanguage: "tur",
                  }}
                  onSubmit={this.completeRegistration}
                >
                  <div className="d-flex ">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block py-2 fs-13 border-radius-8 flex-fill"
                    >
                      Gönder
                    </button>
                  </div>
                </BaseForm>
              </div>
            </Modal>

            <Modal
              id="otp-modal"
              className={`modal-container non-modal-height otp-modal ${
                this.state.isOTPInvalid ? "invalid-otp" : ""
              }`}
              show={this.state.otpModalShow}
            >
              <div className="modal-header">
                <div className="modal-header-mp">
                  {this.state.otpType === otpType.phone ? (
                    <h5>Telefon Doğrulama</h5>
                  ) : (
                    <h5>Banka Doğrulama</h5>
                  )}
                  <img src={mp} alt="MasterPass" />
                </div>
                <CloseButton onClick={this.otpModalClose} />
              </div>
              <div className="modal-body">
                {this.state.otpType === otpType.phone ? (
                  <p className="fs-12">
                    Lütfen Telefonunuza MasterPass tarafından gönderilen
                    doğrulama kodunu giriniz.
                  </p>
                ) : (
                  <p className="fs-12">
                    Lütfen Telefonunuza Bankanız tarafından gönderilen doğrulama
                    kodunu giriniz.
                  </p>
                )}
                <BaseForm
                  schema={FormSchemas.otp.schema}
                  uiSchema={FormSchemas.otp.uiSchema}
                  formData={{
                    validationCode: null,
                    referenceNo: refNumber,
                    sendSms: "N",
                    sendSmsLanguage: "tur",
                    pinType: "otp",
                  }}
                  onSubmit={(formData) =>
                    this.validateOTP(formData, this.state.process)
                  }
                >
                  <div className="d-flex justify-content-between align-items-center form-actions">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block py-2 fs-13 border-radius-8"
                    >
                      Gönder
                    </button>
                    {
                      <Countdown
                        key={this.state.renderer}
                        precision={3}
                        date={Date.now() + 180000}
                        renderer={countdownRenderer}
                      />
                    }
                  </div>
                </BaseForm>
              </div>
            </Modal>

            {/* {
              this.state.listAccountName === "" ? (
                <>
                  <div className="form-group form-check z-index-1 mt-2 ml-0 mp-form-save">
                    <input type="checkbox" id="allowmp" style={{ margin: "-10px" }} onChange={this.acceptSave}/>
                    <label htmlFor="allowmp" className="mp-label">
                      <img width="100" src={mp} alt="MasterPass" />
                      <span>
                        Kart bilgilerimi MasterCard altyapısında saklamak ve bir sonraki alışverişimde tekrar kullanmak istiyorum. {" "}
                        <span onClick={this.openMP}>MasterPass nedir?</span>
                      </span>
                    </label>
                  </div>
                  <div className="mpn">Kredi kartı bilgileriniz dogrukaynak.com tarafından saklanmamaktadır. Ödeme altyapısı MasterCard tarafından sağlanmaktadır.</div>
                </>
              ) : null
            } */}

            {/* {
              !this.state.status.endUserHasMasterpassAccount ? (
                <>
                  <div className="form-group form-check z-index-1 mt-2 ml-0">
                    <input type="checkbox" id="allowmp" style={{ margin: "-10px" }} onChange={this.acceptSave}/>
                    <label htmlFor="allowmp">Kredi kartı bilgilerimin MasterPass ile kaydedilmesini onaylıyorum.</label>
                  </div>
                </>
              ) : null
            } */}
            {/* {
              this.state.status.endUserHasMasterpassAccount && !this.state.status.endUserHasMasterpassCreditCard ? (
                <div>
                  Masterpass account var kredi kartı yok
                </div>
              ) : null
            } */}
            {this.state.status.endUserHasMasterpassAccount &&
            this.state.status.endUserHasMasterpassCreditCard &&
            !this.state.status.endUserHasRelationWithCompany ? (
              <>
                <div className="note linked">
                  <div className="note-content">
                    <div className="note-content-head">
                      <h6>Masterpass'e kayıtlı kartlarınız var.</h6>
                      <img src={mp} alt="" />
                    </div>
                    <p>
                      {this.props.user.mobile} nolu cep telefonu ile
                      Masterpass'e kayıtlı kartlarınız var. Bu kartları
                      dogrukaynak.com'da kullanmak istiyor musunuz?
                    </p>
                    <button
                      onClick={this.linkCards}
                      type="button"
                      className="btn btn-dark btn-sm"
                    >
                      Evet
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            {this.state.status.endUserHasMasterpassAccount &&
            this.state.status.endUserHasMasterpassCreditCard &&
            this.state.status.endUserHasRelationWithCompany ? (
              <>
                <div className="mp-list-card-header">
                  <h2>MasterPass'e Kayıtlı Kartlarınız</h2>
                  <img src={mp} alt="" />
                </div>

                <form
                  action=""
                  method="POST"
                  id="card-list-form"
                  onSubmit={this.deleteCard}
                >
                  <ul className="card-list" key={this.state.binNumber}>
                    {this.state.cards.map((card) => (
                      <li key={card.UniqueId}>
                        <div className="form-group form-check z-index-1 mt-2 ml-0">
                          <input
                            defaultChecked={
                              this.state.binNumber === card.Value1
                            }
                            onChange={(e) => this.purchaseMasterpass(e, card)}
                            type="checkbox"
                            name="accountAliasName"
                            id={card.UniqueId}
                            value={card.Name}
                            style={{ margin: "-10px" }}
                          />
                          <label
                            className="text-truncate"
                            htmlFor={card.UniqueId}
                            title={card.Name}
                          >
                            {card.Name}
                          </label>
                        </div>
                        <div className="d-flex justify-content-around align-items-center">
                          <div className="mp-bin">{card.Value1}</div>
                          <button
                            type="submit"
                            className="btn btn-dark btn-sm"
                            title="Kartı Sil"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <input type="hidden" name="msisdn" value={msisdn} />
                  <input type="hidden" name="token" value={token} />
                  <input type="hidden" name="referenceNo" value={refNumber} />
                  <input type="hidden" name="sendSmsLanguage" value="tur" />
                  <input type="hidden" name="sendSms" value="N" />
                </form>

                {this.props.show3DCheckbox ? (
                  <div className="form-group form-check z-index-1 mt-2 ml-0">
                    <input
                      type="checkbox"
                      id="check3"
                      onChange={(e) => this.toggle3D(e, type3D.masterpass)}
                      checked={
                        (this.props.isSecure &&
                          this.state.type3D === type3D.masterpass) ||
                        this.props.cardInfo.isFull3D
                      }
                      style={{ margin: "-10px" }}
                      disabled={this.props.cardInfo.isFull3D}
                    />
                    <label htmlFor="check3">
                      3D Secure kullanmak istiyorum.
                    </label>
                  </div>
                ) : null}

                {this.state.paymentType === paymentType.masterpass ? (
                  <div
                    className="hideMPList"
                    onClick={(e) =>
                      this.setPaymentType(e, paymentType.creditCard)
                    }
                  >
                    Farklı bir kartla öde
                  </div>
                ) : null}

                {this.state.paymentType === paymentType.creditCard ? (
                  <div
                    className="showMPList"
                    onClick={(e) =>
                      this.setPaymentType(e, paymentType.masterpass)
                    }
                  >
                    Kayıtlı kartımla ödemek istiyorum
                  </div>
                ) : null}

                {/* <div className="note security">
                    <div>
                      Kredi kartı bilgileriniz dogrukaynak.com tarafından saklanmamaktadır.
                    </div>
                    <div>
                      <b>Ödeme altyapısı MasterPass tarafından sağlanmaktadır.</b>
                      <img src={masterpassLogo2} alt=""/>
                    </div>
                  </div> */}
              </>
            ) : null}
          </>
        ) : null}
        {this.state.paymentType === paymentType.creditCard ||
        !this.userHasMobileNumber() ? (
          <>
            <div className="position-relative mt-3">
              <CreditCard
                onChange={this.props.handleCreditCardChange}
                card={this.props.card}
              />
            </div>

            {this.props.show3DCheckbox ? (
              <div className="form-group form-check z-index-1 mt-2 ml-0">
                <input
                  type="checkbox"
                  id="check4"
                  onChange={(e) => this.toggle3D(e, type3D.creditCard)}
                  checked={
                    (this.props.isSecure &&
                      this.state.type3D === type3D.creditCard) ||
                    this.props.cardInfo.isFull3D
                  }
                  style={{ margin: "-10px" }}
                  disabled={this.props.cardInfo.isFull3D}
                />
                <label htmlFor="check4">3D Secure kullanmak istiyorum.</label>
              </div>
            ) : null}
            {this.userHasMobileNumber() && (
              <>
                <div className="form-group form-check z-index-1 mt-2 ml-0 mp-form-save">
                  <input
                    type="checkbox"
                    id="allowmp"
                    style={{ margin: "-10px" }}
                    onChange={this.acceptSave}
                  />
                  <label htmlFor="allowmp" className="mp-label">
                    <img width="100" src={mp} alt="MasterPass" />
                    <span>
                      Kart bilgilerimi MasterCard altyapısında saklamak ve bir
                      sonraki alışverişimde tekrar kullanmak istiyorum.{" "}
                      <span onClick={this.openMP}>MasterPass nedir?</span>
                    </span>
                  </label>
                </div>
                {/* <div className="mpn">
                  Kredi kartı bilgileriniz dogrukaynak.com tarafından
                  saklanmamaktadır. Ödeme altyapısı MasterCard tarafından
                  sağlanmaktadır.
                </div> */}
              </>
            )}
          </>
        ) : null}
        { !this.props.isDigitalCoin &&
          this.props.cardInfo &&
          this.props.cardInfo.installments &&
          this.props.cardInfo.installments.length > 1 && (
            <div className="position-relative mt-3">
              <h6 className="fs-14 font-weight-600 color-919191 z-index-1">
                Taksit Seçenekleri
                {this.props.cardInfo.bankName &&
                this.props.cardInfo.bankName.length
                  ? ` (${this.props.cardInfo.bankName})`
                  : ""}
              </h6>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-sm table-installment">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Taksit</th>
                        <th scope="col">Toplam</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.cardInfo.installments.map((i) => {
                        const { total, shippingPrice} = this.props;

                        var monthlyAmount =
                          (((total - shippingPrice ) * (100 + i.rate)) / 100 + shippingPrice) / i.installment;
                        var totalAmount = monthlyAmount * i.installment;
                        return (
                          <tr
                            className={`${
                              i.installment == this.props.installment
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              this.props.setInstallment(i.installment, i.rate)
                            }
                            key={i.installment}
                          >
                            <th scope="row">{i.installment} x</th>
                            <td>
                              {replaceDotWithComma(monthlyAmount.toFixed(2))} ₺
                            </td>
                            <td>
                              {replaceDotWithComma(totalAmount.toFixed(2))} ₺
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

Masterpass.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  masterpassTokens: AccountReducer.masterpassTokens,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Masterpass);
