import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal/Index";
import CloseButton from "../../components/Button/Close";
import * as actions from "../../routes/actions/Account";
import { Formik, Form } from "formik";
import Icon from "../../components/Icon/Index";
import * as Yup from "yup";
import Select from "react-select";
import classNames from "classnames";
import {
  contractHelper,
  isPasswordValid,
  customClassroomStyles,
  customClassroomStylesResponsive,
} from "../../helpers";
import Turnstile, { useTurnstile } from "react-turnstile";
import { turnstileSiteKeys } from "../../services/constants";

const RegisterInitialValues = {
  email: "",
  password: "",
  classId: "",
  news: false,
  cfToken: "",

  signedContracts: [],
  callConsent: false,
  smsConsent: false,
  emailConsent: false,
};

const RegisterValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Lütfen geçerli bir email adresi giriniz.")
    .required("E-posta adresi bilgisi boş olamaz."),
  password: Yup.string()
    .required("Şifre bilgisi boş olamaz.")
    .min(8, "Şifre en az 8 karakter olmalıdır.")
    .test(
      "password",
      "Şifreniz ardışık sayılar, ardışık harfler, birbirini tekrar eden karakterler içermemelidir!",
      (value) => {
        return value && isPasswordValid(value);
      }
    ),
  classId: Yup.number().required("Lütfen seçim yapınız."),
  cfToken: Yup.string().required("Lütfen robot doğrulamasını yapınız."),
});

const SignupContracts = ({ contracts, suffix, showContract }) => {
  let s = suffix || "";
  return (
    <>
      {contracts.map((contract, i) => (
        <React.Fragment key={i}>
          <Link
            onClick={() => {
              showContract(contract);
            }}
            className="text-underline"
            key={i}
          >
            {contract.contractName}
          </Link>
          {i !== contracts.length - 1 ? `${s}, ` : `${s} `}
        </React.Fragment>
      ))}
    </>
  );
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},

      showContract: false,

      contractToShow: {},
      turnstileWidgetId: "",
      submitEnabled: false,
      registerValidationSchema: RegisterValidationSchema,
      registerInitialValues: RegisterInitialValues,
    };

    this.register = this.register.bind(this);
  }

  register(user) {
    user.contract = true;

    this.props.register(user);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true && this.props.show === false) {
      if (!this.props.contracts || !this.props.contracts.length) {
        this.props.getContracts();
      }
    }
  }

  showContractModal = (contract) => {
    this.setState({ showContract: true, contractToShow: contract });
  };

  hideContractModal = () => {
    this.setState({ showContract: false, contractToShow: {} });
  };

  isArrayEqual = (arr1, arr2) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  };

  componentDidUpdate(prevProps) {
    // props.contracts is an array of contracts
    if (
      this.props.contracts &&
      this.props.contracts.length &&
      prevProps.contracts &&
      !this.isArrayEqual(prevProps.contracts, this.props.contracts)
    ) {
      this.setState({
        registerValidationSchema: this.generateRegisterSchema(),
        registerInitialValues: this.generateRegisterInitialValues(),
      });
    }
  }

  generateRegisterInitialValues = () => {
    let signupContracts = this.props.contracts.filter(
      (c) => c.places && c.places.indexOf("Signup") > -1
    );
    var newFields = signupContracts.reduce((acc, contract) => {
      acc["contract" + contract.contractId] = false;
      return acc;
    }, {});
    var newInitialValues = { ...RegisterInitialValues, ...newFields };
    return newInitialValues;
  };

  generateRegisterSchema = () => {
    let signupContracts = this.props.contracts.filter(
      (c) => c.places && c.places.indexOf("Signup") > -1
    );
    var newFields = signupContracts.reduce((acc, contract) => {
      acc["contract" + contract.contractId] = Yup.boolean().oneOf(
        [true],
        "Lütfen kabul ediniz."
      );
      return acc;
    }, {});
    var newSchema = RegisterValidationSchema.shape(newFields);
    return newSchema;
  };

  render() {
    const { showContract, contractToShow } = this.state;
    let signupContracts = this.props.contracts.filter(
      (c) => c.places && c.places.indexOf("Signup") > -1
    );
    let signupInfoContracts = this.props.contracts.filter(
      (c) => c.places && c.places.indexOf("SignupInfo") > -1
    );
    let signupOptionalContracts = this.props.contracts.filter(
      (c) => c.places && c.places.indexOf("SignupOptional") > -1
    );

    const items = [];
    for (let i = 3; i < 13; i++) {
      items.push({ value: i, label: `${i}. Sınıf` });
    }

    return (
      <>
        {showContract ? (
          <Modal
            className="modal-container non-modal-height upper-element"
            dialogClassName="modal-lg"
            show={this.props.show}
            onCloseClick={this.props.onCloseClick}
          >
            <div className="modal-header bt-dedede:50">
              <h5
                className="modal-title text-center font-weight-600 color-484848"
                style={{ fontSize: 20 }}
              >
                {contractToShow.contractName}
              </h5>
              <CloseButton onClick={() => this.hideContractModal()} />
            </div>
            <div className="modal-body">
              <div className="card mb-2">
                <div
                  className="card-body pt-0 fs-14"
                  style={{ height: "400px", overflowY: "scroll" }}
                  dangerouslySetInnerHTML={{
                    __html: contractHelper.fillContractFields(
                      contractToShow.content,
                      contractHelper.updateFields(contractToShow.contractKeys, {
                        user: this.props.user,
                      })
                    ),
                  }}
                ></div>
              </div>
            </div>
          </Modal>
        ) : null}
        {this.props.show && (
          <Modal
            className="registerModal modal-container"
            show={this.props.show}
            onCloseClick={this.props.onCloseClick}
          >
            <div className="modal-header d-flex justify-content-center bt-dedede:50">
              <div className="modal-tab">
                <button
                  type="button"
                  className="btn"
                  name="login"
                  onClick={this.props.onClick}
                >
                  Giriş Yap
                </button>
                <button
                  type="button"
                  className="btn active"
                  name="register"
                  onClick={this.props.onClick}
                >
                  Üye Ol
                </button>
              </div>
              <CloseButton onClick={this.props.onCloseClick} />
              <span />
            </div>
            <div className="modal-body">
              <Formik
                initialValues={this.state.registerInitialValues}
                onSubmit={async (values, { resetForm }) => {
                  this.register(values);
                  resetForm(this.state.registerInitialValues);
                }}
                validationSchema={this.state.registerValidationSchema}
                enableReinitialize={true}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  handleChange,
                  isSubmitting,
                }) => (
                  <Form>
                    <div className="form-row mb-3">
                      <div className="form-group col">
                        <label htmlFor="email" className="d-flex payment-label">
                          E-posta adresi
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control ${
                            errors.email && touched.email
                              ? "is-invalid"
                              : "valid"
                          }`}
                          onChange={handleChange("email")}
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback ml-3">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row mb-3">
                      <div className="form-group col">
                        <label htmlFor="email" className="d-flex payment-label">
                          Şifre
                        </label>
                        <div className="input-group">
                          <input
                            className={`form-control ${
                              errors.password && touched.password
                                ? "is-invalid"
                                : "valid"
                            }`}
                            type={this.state.showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChange("password")}
                          />
                          <div
                            className="input-group-append"
                            onClick={() => {
                              this.setState((previousState) => ({
                                showPassword: !previousState.showPassword,
                              }));
                            }}
                          >
                            <span className="badge verified-badge">
                              {this.state.showPassword ? (
                                <Icon icon="iEyeMask" />
                              ) : (
                                <Icon icon="iEyeUnmask" />
                              )}
                            </span>
                          </div>
                        </div>

                        {errors.password && touched.password && (
                          <div className="invalid-feedback ml-3">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row mb-3">
                      <div className="form-group col">
                        <label htmlFor="email" className="d-flex payment-label">
                          Sınıf
                        </label>

                        <Select
                          name="classId"
                          isSearchable={false}
                          placeholder={"Seçiniz..."}
                          options={items}
                          className={
                            errors.classId && touched.classId
                              ? "is-invalid"
                              : "valid"
                          }
                          styles={
                            window.innerWidth > 568
                              ? customClassroomStyles
                              : customClassroomStylesResponsive
                          }
                          onChange={(selected) => {
                            setFieldValue("classId", selected.value);
                          }}
                          value={items.find(
                            (item) => item.value === values.classId
                          )}
                        />
                        {errors.classId && touched.classId && (
                          <div className="invalid-feedback ml-3">
                            {errors.classId}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="d-flex justify-content-center">
                      <Turnstile
                        sitekey={turnstileSiteKeys.Register}
                        autoResetOnExpire={true}
                        onLoad={(widgetId) => {
                          this.setState({
                            turnstileWidgetId: widgetId,
                            submitEnabled: false,
                          });
                        }}
                        onVerify={(token) => {
                          setFieldValue("cfToken", token);
                          this.setState({ submitEnabled: true });
                        }}
                        onExpire={() => {
                          this.setState({ submitEnabled: false });
                          window.turnstile.reset(this.state.turnstileWidgetId);
                        }}
                        onError={() => {
                          this.setState({ submitEnabled: false });
                          window.turnstile.reset(this.state.turnstileWidgetId);
                        }}
                        language="tr"
                      />
                    </div>

                    {signupInfoContracts && signupInfoContracts.length > 0 && (
                      <div className="form-group">
                        <label className="longText m-0">
                          {"Kişisel verileriniz "}
                          <SignupContracts
                            contracts={signupInfoContracts}
                            showContract={(c) => this.showContractModal(c)}
                          />{" "}
                          kapsamında işlenmektedir.
                        </label>
                      </div>
                    )}

                    {signupContracts &&
                      signupContracts.map((c, i) => {
                        return (
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              name={"contract" + c.contractId}
                              id={"contract" + c.contractId}
                              // required={true}
                              onClick={(e) => {
                                var signedContracts = values.signedContracts;
                                if (e.target.checked) {
                                  signedContracts.push(c.contractId);
                                } else {
                                  signedContracts = signedContracts.filter(
                                    (x) => x !== c.contractId
                                  );
                                }
                                setFieldValue(
                                  "signedContracts",
                                  signedContracts
                                );
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "contract" + c.contractId,
                                  e.target.checked
                                );
                              }}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor={"contract" + c.contractId}
                              className="longText"
                              style={{ marginTop: 0 }}
                            >
                              <SignupContracts
                                contracts={[c]}
                                showContract={(c) => this.showContractModal(c)}
                                suffix={"'ni"}
                              />
                              {"okudum ve anladım. "}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {errors["contract" + c.contractId] &&
                              touched[["contract" + c.contractId]] && (
                                <div className="invalid-feedback ml-3">
                                  {errors["contract" + c.contractId]}
                                </div>
                              )}
                          </div>
                        );
                      })}
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        name="news"
                        id="news"
                        onChange={(e) => {
                          setFieldValue("news", !values.news);
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="news"
                        className="longText"
                        style={{ marginTop: 0 }}
                      >
                        İletişim bilgilerim üzerinden pazarlama ve tanıtım
                        amaçlı elektronik ileti almak istiyorum.
                      </label>
                    </div>

                    {/* <div className="form-group">
                      <label className="longText m-0">
                        İletişim bilgilerimi üzerinden pazarlama ve tanıtım
                        amaçlı irtibata geçilmesini kabul ediyorum.
                      </label>
                    </div>

                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        name="emailConsent"
                        id="emailConsent"
                        onChange={(e) => {
                          setFieldValue("emailConsent", !values.emailConsent);
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="emailConsent"
                        className="longText"
                        style={{ marginTop: 0 }}
                      >
                        E-posta gönderim izni
                      </label>
                    </div>
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        name="callConsent"
                        id="callConsent"
                        onChange={(e) => {
                          setFieldValue("callConsent", !values.callConsent);
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="callConsent"
                        className="longText"
                        style={{ marginTop: 0 }}
                      >
                        Arama izni
                      </label>
                    </div>
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        name="smsConsent"
                        id="smsConsent"
                        onChange={(e) => {
                          setFieldValue("smsConsent", !values.smsConsent);
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="smsConsent"
                        className="longText"
                        style={{ marginTop: 0 }}
                      >
                        SMS izni
                      </label>
                    </div> */}

                    <button
                      type="submit"
                      className={`btn btn-primary btn-block font-weight-bold py-2 my-3 fs-16 border-radius-12 ${
                        isSubmitting
                          ? //   ||
                            // !(
                            //   !signupContracts ||
                            //   values.signedContracts.every((sc) =>
                            //     signupContracts.some((c) => c.contractId === sc)
                            //   )
                            // )
                            " disabled"
                          : ""
                      }`}
                      style={{ height: 56 }}
                      disabled={isSubmitting || !this.state.submitEnabled}
                    >
                      Üye Ol
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

Register.propTypes = {};
const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  contracts: AccountReducer.contracts,
});

const mapDispatchToProps = {
  getContracts: actions.getContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
