import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { pay3d, pay3dcoin } from "../../routes/actions/Account";
import { toast } from "react-toastify";
import {  keys } from "../../services/constants";
import storage from "../../services/storage";


class Pay3d extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      attributes: {},
      url: null
    };
  }
  componentDidMount() {
    window.addEventListener("message", this.handleFrameMessage);
    window.parent.postMessage(
      { source: "payment-frame-load", payload: undefined },
      "*"
    );
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleFrameMessage);
  }

  handleFrameMessage = async e => {
    if (e.data.type === "LOAD_PAYMENT") {
      const { match, pay3d, pay3dcoin } = this.props;
      var result = { success: false };
      if (match.params.type === "jeton")
        result = await pay3dcoin(match.params.id, e.data.payload);
      else result = await pay3d(match.params.id, e.data.payload);
      if (result.success === true) {
        if (e.data.innerHtml) {
          // Prepared html.
          document.write(result.data.attributes.html);
          this.form.current.submit();
        } else {
          // Prepare html.
          this.setState(
            { attributes: result.data.attributes, url: result.data.paymentUrl },
            () => this.form.current.submit()
          );
        }
      }
      else{
        storage.setItem(keys.CouponDiscount, null);
        this.props.pageVisited(match.params.id);
        toast.error(result.data.message)
      }
    }
  };

  render() {
    return (
      <div>
        <div style={{textAlign:"center",paddingTop : "250px"}}><h2>Yükleniyor...</h2></div>
        <form
          action={this.state.url}
          method="post"
          onLoad={this.handleLoad}
          onSubmit={this.handleSubmit}
          ref={this.form}
          encType="application/x-www-form-urlencoded"
        >
          {Object.keys(this.state.attributes).map(name => (
            <input
              type="hidden"
              name={name}
              defaultValue={this.state.attributes[name]}
              key={name}
            />
          ))}
        </form>
      </div>
      
    );
  }
}

Pay3d.propTypes = {
  match: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  pay3d,
  pay3dcoin
};

export default connect(
  null,
  mapDispatchToProps
)(Pay3d);
