import React, { PureComponent } from "react";
//import Rating from "react-rating";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Comments from "./Comments";
import { Tooltip } from "../../components";

import * as actions from "../../routes/actions/Product";
import {
  loginRequired,
  addToFavorites,
  getFavorites,
  removeFromFavorites,
} from "../../routes/actions/Account";
import {
  favoriteTypes,
  productDetailSuffixPhrase2,
} from "../../services/constants";
import classNames from "classnames";
import bsCustomFileInput from "bs-custom-file-input";
import { getSettings } from "../../routes/actions/Settings";
import {
  // SearchStore,
  // Gallery,
  Breadcrumb,
  ScoreBadge,
  RatingSelect,
} from "../../components";
import ImageGallery from "react-image-gallery";
import { iconHearth, iconHearthOuter } from "../../assets/images/icons";
import { calc } from "../../helpers/mathExtensions";
import { replaceDotWithComma } from "../../helpers";

import PlayList from "./PlayList";
import VideoList from "./VideoList";
import { Helmet } from "react-helmet";
import { setPrerenderTimeout } from "../../helpers";
import { productDetailSuffixPhrase } from "../../services/constants";

import { ReactPlyr, InteractionWrapper, Button } from "../../components";
import Icon from "../../components/Icon/Index";
import ProductDetailSlider from "./ProductDetailSlider";
import ProductBookRecommendation from "./ProductBookRecommendation";
import ProductRecommendationSlider from "./ProductRecommendationSlider";
// import ProductCompare from "./ProductCompare";
import MainBookSlider from "../Main/MainBookSlider";
import ProductDiscountSlider from "./ProductDiscountSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Questions from "./Questions";
import { setVisitProducts } from "../../helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

class ContentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.productDetailRef = React.createRef();
    this.state = {
      done: false,
    };
  }

  appendEmbedDiv = () => {
    if (this.state.done) {
      return;
    }
    this.setState({ done: true }, () => {
      let content = document.getElementsByClassName(
        "product-detail-content"
      )[0];
      let frames = content.getElementsByTagName("iframe");
      if (window.location.href.indexOf("localhost") === -1) {
        for (let frame of frames) {
          frame.style.display = "none";
          // burası iframe'e responsive özellik kazandırmak için
          // let parent = frame.parentElement;
          // let embedDiv = document.createElement("div");
          // embedDiv.setAttribute("class", "embed-responsive embed-responsive-16by9");
          // parent.insertBefore(embedDiv, frame)
          // embedDiv.appendChild(frame);
        }
      } else {
        for (var i = 0; i < frames.length; i++) {
          frames[i].parentNode.removeChild(frames[i]);
        }
      }
    });
  };

  componentDidMount() {
    // this.productDetailRef.addEventListener('DOMSubtreeModified', this.appendEmbedDiv);
  }
  componentWillUnmount() {
    // if (this.productDetailRef)
    //     this.productDetailRef.removeEventListener('DOMSubtreeModified', this.appendEmbedDiv);
  }
  componentDidUpdate() {
    setPrerenderTimeout();
  }
  replaceYtHttp(str) {
    str = str.replace(new RegExp("//www.youtu", "g"), "https://www.youtu");
    str = str.replace(new RegExp("//youtu", "g"), "https://youtu");
    str = str.replace(new RegExp("http://youtu", "g"), "https://youtu");
    str = str.replace(new RegExp("http://www.youtu", "g"), "https://www.youtu");
    return str;
  }

  render() {
    const { product } = this.props;
    var videoId =
      product &&
      product.body &&
      window.location.hostname.indexOf("localhost") === -1
        ? GetYoutubeIdFromHtml(product.body)
        : null;
    product.body = product.body && this.replaceYtHttp(product.body);

    return (
      <>
        <div className="product-detail-container">
          <text className="product-detail-name my-2"></text>
          <div className="row">
            <div className="col-md-12" key={videoId}>
              {videoId && (
                <>
                  <InteractionWrapper
                    videoProvider="youtube"
                    contentType="urun"
                    contentId={product.id}
                    userId={
                      (this.props && this.props.user && this.props.user.id) || 0
                    }
                  >
                    {(markAsWatched) => (
                      <ReactPlyr
                        url={videoId}
                        type={"youtube"}
                        markAsWatched={markAsWatched}
                      />
                    )}
                  </InteractionWrapper>
                </>
              )}
            </div>
          </div>
          <div className="product-detail-content">
            <p
              dangerouslySetInnerHTML={{ __html: product.body }}
              ref={(r) => (this.productDetailRef = r)}
            />
          </div>
        </div>
      </>
    );
  }
}

// const Thumbnails = ({ previews }) => (
//   <>
//     <div className="d-flex flex-row thumbnails-container">
//       <Gallery images={previews} />
//     </div>
//   </>
// );

const GetYoutubeIdFromHtml = (htmlText) => {
  var match = htmlText.match(/src="([^"]+)"/);
  if (match && match.length) {
    const url = match[match.length - 1];
    const urlParts = url.split("/");
    const idWithQueryString = urlParts[urlParts.length - 1];
    var id = idWithQueryString.split("?")[0];
    return id;
  }
  return "";
};

class Show extends PureComponent {
  constructor(props) {
    super(props);

    const { productId } = this.props.match.params || 0;
    this.productId = parseInt(productId, 10);
    this.state = {
      tabName: "detail",
      commentShow: false,
      videoShow: false,
      playing: false,
      url: "",
      show: false,
      productId: this.productId,
      productCount: 1,
      priceType: 0,
      isDigital: false,
      stickyNavbar: false,
      activeItem: "",
    };
    this.productDetailRef = React.createRef();
  }

  detailList = [
    { id: 1, name: "Detay Ver Bana", type: "details" },
    { id: 4, name: "Soru-Cevap", type: "questions" },
    { id: 2, name: "İnsanlar Ne Demiş", type: "comments" },
    { id: 3, name: "İnceleme Videoları", type: "playlist" },
  ];

  async componentDidMount() {
    bsCustomFileInput.init();
    this.props.getProductById(this.productId);
    this.props.getActivityTypes();

    if (this.props.user.id && !this.props.favorites) {
      this.props.getFavorites();
    }
    this.props.getBookAnalysis(this.productId);
    // this.productDetailRef.addEventListener('DOMSubtreeModified', () => {
    //     document.getElementsByTagName('iframe')[0].parentElement.classList.add("embed-responsive")
    //     document.getElementsByTagName('iframe')[0].parentElement.classList.add("embed-responsive-16by9")
    // });
    this.props.getSettings();
  }

  componentWillUnmount() {
    // this.productDetailRef.removeEventListener('DOMSubtreeModified');
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { productId } = this.props.match.params || 0;
    this.productId = parseInt(productId, 10);

    if (prevState.productId !== this.productId) {
      this.setState({
        productId: this.productId,
      });
      this.props.getProductById(this.productId);
    }
    if (this.props.product.id) {
      setVisitProducts(this.props.product);
    }
  }

  handleTabItemClick = (event) => {
    const { name } = event.target;
    this.setState({ tabName: name, playing: false });
  };

  handlePlay = (url) => {
    this.setState({ playing: true, url });
  };

  handleCommentSubmit = async (comment) => {
    const { success, error } = await this.props.postComment(
      this.props.product.id,
      comment
    );

    if (success) {
      this.setState(
        {
          commentShow: false,
        },
        () => {
          toast(this.customToastComment({ success, error }), {
            position: toast.POSITION.BOTTOM_LEFT,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      );
    } else {
      this.setState(
        {
          commentShow: false,
        },
        () => {
          if (error.length) {
            toast.error(error);
          }
        }
      );
    }
  };
  customToastComment = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column" style={{ width: 200 }}>
        <span>Yorumunu aldık. En kısa zamanda cevaplayacağız.</span>
      </div>
    </div>
  );

  handleVideoSubmit = async (productId, file, review) => {
    if (!file && review.url.indexOf("youtu") < 0) {
      toast.error("Geçerli bir bağlantı girin");
      return { error: true };
    }

    const { success, error } = await this.props.sendVideoReview(
      productId,
      file,
      review
    );

    if (success) {
      this.setState({ videoShow: false }, () => {
        toast.success(
          "Gönderdiğin video tarafımıza ulaştı. İnceleme sonrasında sitemizde gösterilip Puan ve Jeton hesabına yüklenecektir. Videon için teşekkürler."
        );
      });
      return { error: false };
    } else {
      if (error && error.length) {
        toast.error(error);
      }
      return { error: true };
    }
  };

  updateProductCount = (count) => {
    this.setState({ productCount: count });
  };

  addProductToBasket = async (product) => {
    const item = {
      key: this.props.basketKey,
      productId: product.id,
      quantity: this.state.productCount,
      update: false,
      ...product,
      isDigital: product.isDigital,
    };

    const { success, error } = await this.props.addProductToBasket(
      item,
      this.props.taxonomies
    );

    if (success) {
      toast.success("Ürün sepetinize eklendi.");
    } else {
      toast.error(error.message);
    }
  };

  isFavorite = () => {
    const { favorites, product } = this.props;
    const products = favorites && favorites.products ? favorites.products : [];
    return products.some((p) => p.id === product.id);
  };

  addProductToNotifyMe = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }
    if (!this.props.enableNotifyMe) return;

    const { success, error } = await this.props.addProductToNotifyMe(product);

    if (success) {
      toast.success(`${product.name} haber verilecek listenize eklendi`);
    } else {
      toast.error(error);
    }
  };

  addToFavorites = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const { success, error } = await this.props.addToFavorites(
      product,
      favoriteTypes.product
    );
    const { taxonomies } = this.props;
    const taxonIds = new Set(
      product?.taxons?.filter((element) => typeof element === "number")
    );

    const productTaxons = taxonomies
      .filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
      .flatMap((t) => {
        return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
      });
    const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
    const productTopTaxon = taxonomies?.filter(
      (t) => t.id == productTopTaxonId
    );
    if (success) {
      toast(this.customToastAddFavourite({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
      if (typeof window !== "undefined") {
        // gtm - datalayer
        if (window.dataLayer != null) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "add_to_wishlist",
            ecommerce: {
              currency: "TRY",
              value: Number(actions.calculateProductPrice(product).toFixed(2)),
              items: [
                {
                  currency: "TRY",
                  item_name: product.name,
                  item_id: product.id,
                  price: Number(
                    actions.calculateProductPrice(product).toFixed(2)
                  ),
                  item_category: productTaxons?.[0]?.name,
                  item_category2: productTaxons?.[1]?.name,
                  item_category3: productTaxons?.[2]?.name,
                  item_category4: productTaxons?.[3]?.name,
                  item_category5: productTaxons?.[4]?.name,
                  item_variant: productTaxons?.[0]?.name ?? product.category,
                  item_list_id: productTopTaxon?.[0]?.id,
                  item_list_name: productTopTaxon?.[0]?.description,
                  quantity: product.quantity,
                  item_brand: product.brand,
                  discount: 0,
                },
              ],
            },
          });
        }
      }
    } else {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  customToastAddFavourite = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <div>Ürün listene eklendi. </div>
        <a href="/hesabim/favorilerim">Listelerime Git</a>
      </div>
    </div>
  );
  removeFromFavorites = async (product) => {
    const { success, error } = await this.props.removeFromFavorites(
      product,
      favoriteTypes.product
    );

    if (success) {
      toast.success("Ürün Listenizden Çıkarıldı.", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  handleToggleComment = async (productId) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }
    // && this.props.user.id && this.props.comments.find(p => p.customerId == this.props.user.id)
    if (!this.state.commentShow) {
      const { success, error } = await this.props.checkComment(productId);
      if (success) {
        toast.error(error);
        return;
      }

      // toast.error("Bu ürüne daha önce yorum yapmışsın, başka bir ürünü değerlendirip puan kazanmaya devam edebilirsin.");
      this.setState({ commentShow: !this.state.commentShow });
    } else {
      this.setState({ commentShow: !this.state.commentShow });
    }
  };

  handleBookTypeChange = (id) => {
    if (this.productId !== id) {
      this.props.history.push(`/urunler/detay/${id}`);
    }
    // this.setState({ priceType })
  };

  stickyNavbar = () => {
    var scrollY = window.pageYOffset;
    if (scrollY > 100) {
      this.setState({ stickyNavbar: true });
    } else {
      this.setState({ stickyNavbar: false });
    }
  };
  setImageAttribute() {
    var elements = document.getElementsByClassName("image-gallery-image");
    // for (let i = 0; i < elements.length; i++) {
    //   elements[i].getElementsByTagName("IMG")[0].setAttribute("width", "432");
    //   elements[i].getElementsByTagName("IMG")[0].setAttribute("height", "432");
    // }
  }

  render() {
    const { product, bookAnalysis, taxonomies } = this.props;
    const favorite = this.isFavorite();

    window.addEventListener("scroll", this.stickyNavbar);

    // const productExist = this.props.products.some(
    //   (p) => p.id == product.publishedDigitalProductConcatenate
    // );
    // const digitalExist = this.props.products.some((p) => p.id == product.id);
    // const showDijitalBasiliButtons = product.id != product.publishedDigitalProductConcatenate; // && productExist && digitalExist;
    // const pages = samplePages.map((page) => ({ src: page.url }));

    const userComments = [];

    this.props.comments.forEach((comment) => {
      !comment.repliedTo && userComments.push(comment);
    });

    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/",
      },
      {
        title: "Ürünler",
        url: "/urunler",
      },
      {
        title: product.name,
        active: true,
      },
    ];
    const productPriceWithDot = product
      ? actions.calculateProductPrice(product).toFixed(2)
      : 0;
    const productPrice = replaceDotWithComma(productPriceWithDot);

    const priceWithTax =
      product.priceWithTax !== undefined ? product.priceWithTax : 0;
    const formattedPriceWithTax = priceWithTax.toFixed(2).replace(".", ",");
    const shouldShowOldPrice = product.discount !== 0;

    const totalRating = (
      this.props.comments.filter((u)=>u.tur === 0).reduce((a, c) => a + c.star, 0) /
        (this.props.comments.filter((u)=>u.tur === 0).length * 10) || 0
    ).toFixed(1);

    const images = product.images.map((image, i) => ({
      original: image,
      thumbnail: product.avatars[i],
    }));

    // const taxonomy = this.props.taxonomies.find(
    //   (t) => t.code === "kategoriler"
    // );
    // const bsTaxon =
    //   taxonomy &&
    //   taxonomy.taxons.find((taxon) => taxon.code === "cok-satanlar");

    let activityType = { Score: 0, Coins: 0 };
    let reviewActivityType = { Score: 0, Coins: 0 };
    let commentActivityType = { Score: 0, Coins: 0 };
    if (this.props.activityTypes) {
      const result = this.props.activityTypes.find(
        (p) => p.Name === "buy-book"
      );
      if (result) {
        activityType = result;
      }
      const result2 = this.props.activityTypes.find(
        (p) => p.Name === "add-review"
      );
      if (result2) {
        reviewActivityType = result2;
      }
      const result3 = this.props.activityTypes.find(
        (p) => p.Name === "add-comment"
      );
      if (result3) {
        commentActivityType = result3;
      }
    }

    const taxonIds = new Set(
      product?.taxons?.filter((element) => typeof element === "number")
    );

    const productTaxons = taxonomies
      .filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
      .flatMap((t) => {
        return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
      });
    const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
    const productTopTaxon = taxonomies?.filter(
      (t) => t.id == productTopTaxonId
    );
    // const EmptySamplePages = () => (
    //   <>
    //     <div className="empty-sample-pages">
    //       <div className="first-title">Henüz örnek bir sayfa yok.</div>
    //       <div className="second-title">Örnek sayfa yükle kazan</div>
    //       <div className="d-flex">
    //         <ScoreBadge
    //           icon="point"
    //           title={`+${Math.round(productPrice * activityType.Score)}  Puan`}
    //           className="badge-score mr-2"
    //           value={activityType.Score}
    //           productDetailBadge
    //         />
    //         <ScoreBadge
    //           icon="coin"
    //           title={`+${(
    //             calc(productPrice, "*", activityType.Coins) / 10
    //           ).toFixed(0)}  Jeton`}
    //           className="badge-coin"
    //           value={calc(productPrice, "*", activityType.Coins).toFixed(0)}
    //           productDetailBadge
    //         />
    //       </div>
    //       <button className="btn-empty-sample-pages">Örnek Sayfa Yükle</button>
    //     </div>
    //   </>
    // );

    // const Star = (props) => (
    //   <svg
    //     className={props.className}
    //     xmlns="http://www.w3.org/2000/svg"
    //     width="24"
    //     height="24"
    //     viewBox="0 0 24 24"
    //   >
    //     <path
    //       fill="#FBBC06"
    //       fillRule="nonzero"
    //       d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    //     />
    //   </svg>
    // );

    // const StarO = (props) => (
    //   <svg
    //     className={props.className}
    //     xmlns="http://www.w3.org/2000/svg"
    //     width="24"
    //     height="24"
    //     viewBox="0 0 24 24"
    //   >
    //     <path
    //       fill="#DEDEDE"
    //       fillRule="nonzero"
    //       d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    //     />
    //   </svg>
    // );
    setTimeout(() => this.setImageAttribute(), 3000);
    return (
      <>
        {product && product.category && productPrice && this.state.productId ? (
          <Helmet>
            <title>
              {product && product.brand
                ? `${product.brand} ${product.name} ${productDetailSuffixPhrase2}`
                : "Tonguç'la çalış, Başarıya alış!"}
            </title>
            <link rel="canonical" href={window.location.href.split("?")[0]} />
            <meta
              name="description"
              content={`${product.brand} ${product.name} ${productDetailSuffixPhrase}`}
            />

            {product.id == 5821 ? <meta name="robots" content="noindex" /> : ""}

            <script>
              {`gtag('event', 'page_view', {
                'send_to':'AW-596855146',
                'ecomm_pagetype': 'product',
                'ecomm_prodid': ${this.state.productId},
                'ecomm_totalvalue': '${productPriceWithDot}',
                'ecomm_category': ${JSON.stringify(product.category)},
                });`}
            </script>
            <script>
              {`fbq('track', 'ViewContent', {
                content_type: 'product',
                content_category: ${JSON.stringify(product.category)},
                content_name: ${JSON.stringify(product.name)},
                value: ${productPriceWithDot},
                currency: 'TRY',
                content_ids:[${product.id}]
                });`}
            </script>
            <script>
              {`
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                  event: "select_item",
                  'ecommerce': {
                    'items': [{
                      'item_id': ${product.id},
                      'item_name': ${JSON.stringify(product.name)},
                      'currency': "TRY",
                      'index': 0,
                      'discount': 0,
                      'item_brand': ${JSON.stringify(product.brand)},
                      'item_category': ${JSON.stringify(
                        productTaxons?.[0]?.name
                      )},
                      'item_category2': ${JSON.stringify(
                        productTaxons?.[1]?.name
                      )},
                      'item_category3': ${JSON.stringify(
                        productTaxons?.[2]?.name
                      )},
                      'item_category4': ${JSON.stringify(
                        productTaxons?.[3]?.name
                      )},
                      'item_category5': ${JSON.stringify(
                        productTaxons?.[4]?.name
                      )},
                      'item_list_id': ${JSON.stringify(
                        productTopTaxon?.[0]?.id
                      )},
                      'item_list_name':${JSON.stringify(
                        productTopTaxon?.[0]?.description
                      )},
                      'item_variant': ${JSON.stringify(
                        productTaxons?.[0]?.name ?? product.category
                      )},
                      'price': ${productPriceWithDot},
                    }]
                    }});
                `}
            </script>
            <script>
              {`
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                  event: "view_item",
                  'ecommerce': {
                    'items': [{
                      'item_id': ${product.id},
                      'item_name': ${JSON.stringify(product.name)},
                      'currency': "TRY",
                      'index': 0,
                      'item_brand': ${JSON.stringify(product.brand)},
                      'item_category': ${JSON.stringify(
                        productTaxons?.[0]?.name
                      )},
                      'item_category2': ${JSON.stringify(
                        productTaxons?.[1]?.name
                      )},
                      'item_category3': ${JSON.stringify(
                        productTaxons?.[2]?.name
                      )},
                      'item_category4': ${JSON.stringify(
                        productTaxons?.[3]?.name
                      )},
                      'item_category5': ${JSON.stringify(
                        productTaxons?.[4]?.name
                      )},
                      'item_variant': ${JSON.stringify(
                        productTaxons?.[0]?.name ?? product.category
                      )},
                      'item_list_name':${JSON.stringify(
                        productTopTaxon?.[0]?.description
                      )},
                      'item_list_id': ${JSON.stringify(
                        productTopTaxon?.[0]?.id
                      )},
                      'price': ${productPriceWithDot},
                      'discount': 0,
                    }]
                    }});
                `}
            </script>
          </Helmet>
        ) : null}
        <div
          className={`sticky-product-navbar d-none d-md-flex ${
            this.state.stickyNavbar ? "show" : ""
          }`}
           
        >
          <div className="container d-flex align-items-center h-100">
            <div className="d-flex flex-column detail-box">
              <text>{product.name}</text>
              <div className="d-flex align-items-end">
                {shouldShowOldPrice && (
                  <div
                    className={
                      product.priceWithTax !== undefined
                        ? `sticky-old-price`
                        : `d-none`
                    }
                  >
                    {`${formattedPriceWithTax} ₺`}
                  </div>
                )}
                <span>{productPrice} ₺</span>
              </div>
            </div>
            <div className="ml-auto">
              {product?.getable && (
                <form className="">
                  {/* <div className="form-group mr-2 mb-0">
                          <NumericInput
                            onChange={(value) => this.updateProductCount(value)}
                            defaultValue={1}
                          />
                        </div> */}
                  {!product.isInStock && this.props.enableNotifyMe ? (
                    <button
                      type="button"
                      className="btn btn-primary add-basket-button"
                      onClick={() => this.addProductToNotifyMe(product)}
                    >
                      <Icon icon="iMailWhite" />
                      Haber ver
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary add-basket-button"
                      onClick={() => this.addProductToBasket(product)}
                    >
                      <Icon icon="iBasketWhite" />
                      Sepete Ekle
                    </button>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="container align-items-center detail-box-mobile">
          <div className="d-flex flex-column ">
            {shouldShowOldPrice && (
              <div
                className={
                  product.priceWithTax !== undefined
                    ? `sticky-old-price`
                    : `d-none`
                }
              >
                {`${formattedPriceWithTax} ₺`}
              </div>
            )}
            <span>{productPrice} ₺</span>
          </div>
          <div className="ml-auto">
            {product?.getable && (
              <div className="d-flex">
                <button
                  className="btn btn-link product-favourite"
                  onClick={() =>
                    favorite
                      ? this.removeFromFavorites(product)
                      : this.addToFavorites(product)
                  }
                >
                  <img
                    src={favorite ? iconHearth : iconHearthOuter}
                    alt={product.name}
                  />

                  <span className="d-none d-md-block">
                    {favorite ? "Listemden Çıkar" : "Listeme Ekle"}
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary add-basket-button"
                  onClick={() => this.addProductToBasket(product)}
                >
                  <Icon icon="iBasketWhite" />
                  Sepete Ekle
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row product-detail--container">
            <div className="col-12 p-xs-0">
              {product.name === undefined ? (
                <Skeleton variant="text" width={250} />
              ) : (
                <Breadcrumb items={breadcrumbs} />
              )}
            </div>

            <div className="col-12 product-image-container">
              <div className="product-image-gallery">
                <div className="product-features">
                  {product.badges?.map((badge, i) => (
                    <div className={`product-badge ${badge.className}`} key={i}>
                      {badge.textToDisplay}
                    </div>
                  ))}
                </div>
                {images.length === 0 ? (
                  <Skeleton variant="rect" width={432} height={432} />
                ) : (
                  <ImageGallery
                    items={images}
                    thumbnailPosition="bottom"
                    showThumbnails={images.length > 1}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    originalWidth={432}
                    originalHeight={432}
                  />
                )}
              </div>
            </div>
            <div className="col-12 product-detail-box">
              <div className="card product-detail-card">
                <div className="product-detail-card-header">
                  <h1 className="product-detail-card-title">
                    {product.name === undefined ? (
                      <Skeleton variant="text" width={250} />
                    ) : (
                      product.name
                    )}
                  </h1>
                  <a
                    href="#details"
                    onClick={() => this.setState({ tabName: "comments" })}
                    className="rating-component"
                  >
                    {product?.brandsOrtak?.length < 2 ? (
                      <div className="product-brand">
                        Marka :{" "}
                        {product.brand === undefined ? (
                          <Skeleton variant="text" width={75} />
                        ) : (
                          <Link
                            to={`/markalar/detay/${product.brandSlug}?b=${product.brandId}`}
                          >
                            {product.brand}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div className="product-brand">
                        Markalar :{" "}
                        {product?.brandsOrtak?.map((e, i) => {
                          return (
                            <Link
                              to={`/markalar/detay/${e.brandsSlug}?b=${e.id}`}
                            >
                              {product?.brandsOrtak?.length - 1 > i
                                ? e.name + " - "
                                : e.name}
                            </Link>
                          );
                        })}
                      </div>
                    )}
                    <text>
                      <RatingSelect initialRating={totalRating} readonly />
                      <span className="rate-text">
                        {userComments.filter((u) => u.tur === 0).length} yorum
                      </span>
                    </text>
                  </a>

                  {shouldShowOldPrice && (
                    <div
                      className={
                        product.priceWithTax !== undefined
                          ? `price-old`
                          : `d-none`
                      }
                    >
                      {`${formattedPriceWithTax} ₺`}
                    </div>
                  )}

                  <div className="d-flex flex-col">
                    <h5
                      className={`p-0 ${
                        !product.isInStock && this.props.enableNotifyMe
                          ? "product-pale-price"
                          : "product-detail-price"
                      }`}
                    >{`${productPrice} ₺`}</h5>
                  </div>
                  <div className="d-flex flex-col">
                    {product.showBasiliButton && (
                      <Button
                        className={classNames(
                          "product-type",

                          product.urunUretimFormatTipID === 1
                            ? "product-type-selected"
                            : ""
                        )}
                        onClick={() =>
                          this.handleBookTypeChange(
                            product.publishedBasiliProductConcatenate
                          )
                        }
                        key={"product_normal"}
                      >
                        <div className="d-flex flex-row">
                          Fijital Kitap
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip="Fiziksel ve Dijital özellikli kitap"
                          >
                            <FontAwesomeIcon
                              className="ml-1"
                              icon={faQuestionCircle}
                            />
                          </Tooltip>
                        </div>

                        <span>{`${
                          product.urunUretimFormatTipID === 1
                            ? productPrice
                            : product.printedProductPrice
                        } ₺`}</span>
                      </Button>
                    )}

                    {product.showDigitalButton && (
                      <Button
                        className={classNames(
                          "ml-2",
                          "product-type",
                          "product-digital-book",
                          product.urunUretimFormatTipID === 3
                            ? "product-type-selected"
                            : ""
                        )}
                        onClick={() =>
                          this.handleBookTypeChange(
                            product.publishedDigitalProductConcatenate
                          )
                        }
                        key={"product_digital"}
                      >
                        <Icon icon="iDigitalBook" />
                        <div className="d-flex flex-column align-items-start ml-2">
                          <div className="d-flex flex-row">
                            Dijital Kitap
                            <Tooltip
                              placement="top"
                              trigger="hover"
                              tooltip="Sadece web ve mobilde kullanılabilen kitap"
                            >
                              <FontAwesomeIcon
                                className="ml-1"
                                icon={faQuestionCircle}
                              />
                            </Tooltip>
                            <span className="badge">yeni</span>
                          </div>
                          <span>{`${
                            product.urunUretimFormatTipID === 3
                              ? productPrice
                              : product.digitalProductPrice
                          } ₺`}</span>
                        </div>
                      </Button>
                    )}

                    {product.showEkoButton && (
                      <Button
                        className={classNames(
                          "ml-2",
                          "product-type",
                          product.urunUretimFormatTipID === 2
                            ? "product-type-selected"
                            : ""
                        )}
                        onClick={() =>
                          this.handleBookTypeChange(
                            product.publishedEkoProductConcatenate
                          )
                        }
                        key={"product_eko"}
                      >
                        <div className="d-flex flex-column align-items-start ml-2">
                          <div className="d-flex flex-row">Eko Versiyon</div>
                          <span>{`${
                            product.urunUretimFormatTipID === 2
                              ? productPrice
                              : product.ekoProductPrice
                          } ₺`}</span>
                        </div>
                      </Button>
                    )}
                  </div>
                </div>

                <div className="product-detail-card-basket">
                  <div className="product-order-detail">
                    {product?.getable === undefined ? (
                      <form className="form-inline">
                        <div className="form-group mr-2 mb-0">
                          {<Skeleton variant="rect" width={150} height={50} />}
                        </div>
                        <div className="form-group mr-2 mb-0">
                          {<Skeleton variant="rect" width={150} height={50} />}
                        </div>
                      </form>
                    ) : !product.isInStock && this.props.enableNotifyMe ? (
                      <form className="form-inline">
                        <button
                          type="button"
                          className="btn btn-primary add-notify-me-button"
                          onClick={() => this.addProductToNotifyMe(product)}
                        >
                          Haber ver
                        </button>
                      </form>
                    ) : (
                      product?.getable && (
                        <form className="add-basket-button-box">
                          <button
                            type="button"
                            className="btn btn-primary add-basket-button"
                            onClick={() => this.addProductToBasket(product)}
                          >
                            <Icon icon="iBasketWhite" />
                            Sepete Ekle
                          </button>
                        </form>
                      )
                    )}
                    <button
                      className="btn btn-link product-favourite"
                      onClick={() =>
                        favorite
                          ? this.removeFromFavorites(product)
                          : this.addToFavorites(product)
                      }
                    >
                      <img
                        src={favorite ? iconHearth : iconHearthOuter}
                        alt={product.name}
                      />

                      <span className="d-none d-md-block">
                        {favorite ? "Listemden Çıkar" : "Listeme Ekle"}
                      </span>
                    </button>
                  </div>

                  {!product.isDigital && (
                    <div className="cargo-container">
                      <Icon icon="iTruck" />
                      <div className="cargo-text">
                        <span>Hemen</span> sipariş verirseniz en geç{" "}
                        <span>24 saat</span> içinde kargoda (pazar ve resmi
                        tatiller dışında)
                      </div>
                    </div>
                  )}
                  <text>
                    <div className="d-flex flex-row">
                      <ScoreBadge
                        icon="point"
                        title={`+${calc(
                          productPrice,
                          "*",
                          activityType.Score
                        )}  Puan`}
                        className="badge-score mr-2"
                        value={activityType.Score}
                        productDetailBadge
                      />
                      <ScoreBadge
                        icon="coin"
                        title={`+${(
                          calc(productPrice, "*", activityType.Coins) / 10
                        ).toFixed(0)}  Jeton`}
                        className="badge-coin"
                        value={calc(
                          productPrice,
                          "*",
                          activityType.Coins
                        ).toFixed(0)}
                        productDetailBadge
                      />
                    </div>
                  </text>
                </div>
                {!(
                  bookAnalysis.category == null &&
                  bookAnalysis.isbn == null &&
                  bookAnalysis.totalQuestion == null &&
                  bookAnalysis.numberOfPages == null
                ) && (
                  <div className="product-detail-card-analysis">
                    <span>Kitap Analizi</span>
                    {bookAnalysis.enteredRate >= 80 &&
                      bookAnalysis.totalQuestion > 0 && (
                        <ul className="difficulty-level-container">
                          <li className="difficulty-level-item align-items-start">
                            <div className="difficulty-level">
                              Zorluk Seviyesi
                            </div>
                            <div className="question-quantity">Soru Sayısı</div>
                          </li>
                          <li className="difficulty-level-item">
                            <div className="difficulty-level">1</div>
                            <div className="question-quantity">
                              {bookAnalysis.degree1}
                            </div>
                          </li>
                          <li className="difficulty-level-item">
                            <div className="difficulty-level">2</div>
                            <div className="question-quantity">
                              {bookAnalysis.degree2}
                            </div>
                          </li>
                          <li className="difficulty-level-item">
                            <div className="difficulty-level">3</div>
                            <div className="question-quantity">
                              {bookAnalysis.degree3}
                            </div>
                          </li>
                          <li className="difficulty-level-item">
                            <div className="difficulty-level">4</div>
                            <div className="question-quantity">
                              {bookAnalysis.degree4}
                            </div>
                          </li>
                          <li className="difficulty-level-item">
                            <div className="difficulty-level">5</div>
                            <div className="question-quantity">
                              {bookAnalysis.degree5}
                            </div>
                          </li>
                        </ul>
                      )}
                    <div className="product-info-container row m-0">
                      <div className="product-info-item col-6 col-xl-3">
                        <Icon icon="iProductType" />
                        <div className="product-info-body">
                          <text>Türü</text>
                          <span>{bookAnalysis.category}</span>
                        </div>
                      </div>
                      <div className="product-info-item col-6 col-xl-3">
                        <Icon icon="iPageCount" />
                        <div className="product-info-body">
                          <text>Sayfa Sayısı</text>
                          <span>{bookAnalysis.numberOfPages}</span>
                        </div>
                      </div>
                      <div className="product-info-item col-6 col-xl-3">
                        <Icon icon="iQuestionCount" />
                        <div className="product-info-body">
                          <text>Soru Sayısı</text>
                          <span>{bookAnalysis.totalQuestion}</span>
                        </div>
                      </div>
                      <div className="product-info-item col-6 col-xl-3">
                        <Icon icon="iBarcode" />
                        <div className="product-info-body">
                          <text>SBN</text>
                          <span>{bookAnalysis.isbn}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="product-detail-card-details" id="details">
                  <ul className="nav nav-tabs product-detail-tabs">
                    <li className="nav-item">
                      <button
                        className={classNames("btn btn-link", {
                          active: this.state.tabName === "detail",
                        })}
                        onClick={this.handleTabItemClick}
                        name="detail"
                      >
                        Detay Ver Bana
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={classNames("btn btn-link", {
                          active: this.state.tabName === "questions",
                        })}
                        onClick={this.handleTabItemClick}
                        name="questions"
                      >
                        Soru-Cevap
                        {userComments.filter((u) => u.tur === 2).length > 0 &&
                          `(${userComments.filter((u) => u.tur === 2).length})`}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={classNames("btn btn-link", {
                          active: this.state.tabName === "comments",
                        })}
                        onClick={this.handleTabItemClick}
                        name="comments"
                      >
                        İnsanlar Ne Demiş
                        {userComments.filter((u) => u.tur === 0).length > 0 &&
                          `(${userComments.filter((u) => u.tur === 0).length})`}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={classNames("btn btn-link", {
                          active: this.state.tabName === "playlist",
                        })}
                        onClick={this.handleTabItemClick}
                        name="playlist"
                      >
                        İnceleme Videoları(
                        {this.props.videos.length})
                      </button>
                    </li>
                    {product.videos.length > 0 && (
                      <li className="nav-item">
                        <button
                          className={classNames("btn btn-link", {
                            active: this.state.tabName === "videos",
                          })}
                          onClick={this.handleTabItemClick}
                          name="videos"
                        >
                          Tanıtım Videoları ({product.videos.length})
                        </button>
                      </li>
                    )}
                  </ul>
                  {(() => {
                    switch (this.state.tabName) {
                      case "detail":
                        return (
                          <ContentDetail
                            product={product}
                            user={this.props.user}
                          />
                        );
                      case "comments":
                        return (
                          <Comments
                            loginRequired={this.props.loginRequired}
                            onSubmit={this.handleCommentSubmit}
                            show={this.state.commentShow}
                            onToggle={() =>
                              this.handleToggleComment(product.id)
                            }
                            user={this.props.user}
                            product={product}
                            comments={this.props.comments}
                            totalRating={totalRating}
                            price={productPrice}
                            activityType={commentActivityType}
                          />
                        );
                      case "playlist":
                        return (
                          <PlayList
                            loginRequired={this.props.loginRequired}
                            user={this.props.user}
                            onPlay={this.handlePlay}
                            onSubmit={this.handleVideoSubmit}
                            playing={this.state.playing}
                            url={this.state.url}
                            show={this.state.videoShow}
                            onToggle={() =>
                              this.setState({
                                videoShow: !this.state.videoShow,
                              })
                            }
                            modalShow={this.state.show}
                            onShow={() => this.setState({ show: true })}
                            onClose={() => this.setState({ show: false })}
                            videos={this.props.videos}
                            product={product}
                            activityType={reviewActivityType}
                          />
                        );
                      // case "thumbnails":
                      //   return this.props.samplePages.length > 0 ? (
                      //     <Thumbnails previews={pages} />
                      //   ) : (
                      //     <EmptySamplePages />
                      //   );
                      case "questions":
                        return (
                          <Questions
                            loginRequired={this.props.loginRequired}
                            onSubmit={this.handleCommentSubmit}
                            show={this.state.commentShow}
                            onToggle={() =>
                              this.setState({
                                commentShow: !this.state.commentShow,
                              })
                            }
                            user={this.props.user}
                            product={product}
                            comments={this.props.comments}
                            price={productPrice}
                            activityType={commentActivityType}
                          />
                        );
                      case "videos":
                        return <VideoList videos={product.videos} />;
                      default:
                        return null;
                    }
                  })()}
                </div>

                <div className="footer-accordion-container">
                  <ul className="footer-accordion product-accordion">
                    {this.detailList.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={`accordion-item ${
                            this.state.activeItem === item.type ? "show" : ""
                          }`}
                        >
                          <div
                            className={`accordion-head ${
                              this.state.activeItem === item.type ? "show" : ""
                            }`}
                            onClick={() => {
                              this.state.activeItem === item.type
                                ? this.setState({ activeItem: "" })
                                : this.setState({ activeItem: item.type });
                            }}
                          >
                            <h6>{item.name}</h6>
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="accordion-icon"
                            />
                          </div>
                          <div className="accordion-body">
                            {(() => {
                              switch (item.type) {
                                case "details":
                                  return (
                                    <ContentDetail
                                      product={product}
                                      user={this.props.user}
                                    />
                                  );
                                case "comments":
                                  return (
                                    <Comments
                                      loginRequired={this.props.loginRequired}
                                      onSubmit={this.handleCommentSubmit}
                                      show={this.state.commentShow}
                                      onToggle={() =>
                                        this.handleToggleComment(product.id)
                                      }
                                      user={this.props.user}
                                      product={product}
                                      comments={this.props.comments}
                                      totalRating={totalRating}
                                      price={productPrice}
                                      activityType={commentActivityType}
                                    />
                                  );
                                case "playlist":
                                  return (
                                    <PlayList
                                      loginRequired={this.props.loginRequired}
                                      user={this.props.user}
                                      onPlay={this.handlePlay}
                                      onSubmit={this.handleVideoSubmit}
                                      playing={this.state.playing}
                                      url={this.state.url}
                                      show={this.state.videoShow}
                                      onToggle={() =>
                                        this.setState({
                                          videoShow: !this.state.videoShow,
                                        })
                                      }
                                      modalShow={this.state.show}
                                      onShow={() =>
                                        this.setState({ show: true })
                                      }
                                      onClose={() =>
                                        this.setState({ show: false })
                                      }
                                      videos={this.props.videos}
                                      product={product}
                                      activityType={reviewActivityType}
                                    />
                                  );
                                // case "thumbnails":
                                //   return this.props.samplePages.length > 0 ? (
                                //     <Thumbnails previews={pages} />
                                //   ) : (
                                //     <EmptySamplePages />
                                //   );
                                case "questions":
                                  return (
                                    <Questions
                                      loginRequired={this.props.loginRequired}
                                      onSubmit={this.handleCommentSubmit}
                                      show={this.state.commentShow}
                                      onToggle={() =>
                                        this.handleToggleComment(product.id)
                                      }
                                      user={this.props.user}
                                      product={product}
                                      comments={this.props.comments}
                                      price={productPrice}
                                      activityType={commentActivityType}
                                    />
                                  );
                              }
                            })()}
                          </div>
                        </li>
                      );
                      // if (item.type === "comments") {
                      //   return this.props.comments.length > 0 ? (
                      //     <Comments
                      //       loginRequired={this.props.loginRequired}
                      //       onSubmit={this.handleCommentSubmit}
                      //       show={this.state.commentShow}
                      //       onToggle={() =>
                      //         this.handleToggleComment(product.id)
                      //       }
                      //       user={this.props.user}
                      //       product={product}
                      //       comments={this.props.comments}
                      //       totalRating={totalRating}
                      //       price={productPrice}
                      //       activityType={commentActivityType}
                      //     />
                      //   ) : (
                      //     <EmptyCommentPage />
                      //   );
                      // }
                    })}
                  </ul>
                </div>

                {/*
                <div className="product-tags">
                  {bsTaxon &&
                  product.taxons &&
                  product.taxons.some((t) => t === bsTaxon.id) ? (
                    <div className="product-tag-item">
                      <span className="product-tag-icon">
                        <img
                          src={require("../../assets/images/price.svg")}
                          alt="Çok Satan"
                        />
                      </span>
                      <span className="product-tag-name">Çok Satan</span>
                    </div>
                  ) : null}
                  {product.hasDigitalBook === true ? (
                    <div className="product-tag-item">
                      <span className="product-tag-icon">
                        <img
                          src={require("../../assets/images/OkumaModu.svg")}
                          alt="Çalışma modu uyumlu"
                        />
                      </span>
                      <span className="product-tag-name">
                        Çalışma modu uyumlu
                      </span>
                    </div>
                  ) : null}


              Bu bölüm daha sonra yapılacak. commentliyorum. bknz -> Jira ID = STX-479
              <div className="product-tag-item">
                <span className="product-tag-icon">
                  <img
                    src={require("../../assets/images/icons/doc.svg")}
                    alt="Program List"
                  />
                </span>
                <span className="product-tag-name">Program List</span>
              </div>

                </div> */}

                {/* <div className="product-search-store">
                  <SearchStore
                    product={product}
                    productCount={this.state.productCount}
                    stores={this.props.stores}
                    getStores={this.props.getStores}
                  />
                </div> */}
              </div>
            </div>

            <div
              className={classNames("col-xl-12 mb-4", {
                "d-md-none": this.state.tabName !== "comments",
              })}
            />
          </div>
        </div>

        <div className="slider-section">
          <div className="container ">
            <ProductDetailSlider
              title={"Set Fırsatları"}
              productId={product.id}
            />
            {product?.getable && (
              <ProductBookRecommendation
                eventType={4}
                productId={this.productId}
                product={product}
                title={"Sana Özel Set"}
              />
            )}
            <ProductRecommendationSlider
              eventType={4}
              productId={this.productId}
              title={"Önermedi Deme"}
            />
            {/* <ProductCompare /> */}
            <MainBookSlider
              title={"Son Baktıklarım"}
              url={"/products/recently-viewed"}
              loggedIn={this.props.user.id > 0}
            />
            <ProductDiscountSlider />
          </div>
        </div>
      </>
    );
  }
}

Show.propTypes = {};

const mapStateToProps = ({
  AccountReducer,
  ProductReducer,
  SettingsReducer,
}) => ({
  activityTypes: ProductReducer.activityTypes,
  user: AccountReducer.user,
  stores: ProductReducer.stores,
  products: ProductReducer.products,
  product: ProductReducer.product,
  comments: ProductReducer.comments,
  samplePages: ProductReducer.samplePages,
  basketKey: ProductReducer.basketKey,
  taxonomies: ProductReducer.taxonomies,
  videos: ProductReducer.videos,
  favorites: AccountReducer.favorites,
  bookAnalysis: ProductReducer.bookAnalysis,
  enableNotifyMe: SettingsReducer.enableNotifyMe,
});

const mapDispatchToProps = {
  loginRequired: loginRequired,
  getProductsById: actions.getProductsById,
  getProductById: actions.getProductById,
  addProductToBasket: actions.addProductToBasket,
  getFavorites: getFavorites,
  addToFavorites: addToFavorites,
  removeFromFavorites: removeFromFavorites,
  search: actions.search,
  postComment: actions.postComment,
  checkComment: actions.checkComment,
  sendVideoReview: actions.sendVideoReview,
  getStores: actions.getStores,
  getActivityTypes: actions.getActivityTypes,
  getBookAnalysis: actions.getBookAnalysis,
  addProductToNotifyMe: actions.addProductToNotifyMe,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Show);
