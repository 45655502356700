import React, { useState }  from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { withRouter, Link, useParams } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Account";
import { HTTP} from "../../services/constants";
import { tatsApiCall } from "../../services/api";
import Icon from "../../components/Icon/Index";
import {
  Breadcrumb,
} from "../../components";

const PdfViewer = ({location, history, user, loginRequired}) => {

  const params = new URLSearchParams(location.search);
  const file = params.get("file");
  const { id } = useParams();

  const [allPageNumbers, setAllPageNumbers] = useState();
  const PAGE_MAX_HEIGHT = 600; // maxHeight for scroll

  const [outerWidth, setOuterWidth] = useState();
  const CONTAINER_PADDING = 30;

  if (!user.id) {
    return (
      <div>
        {loginRequired(true)}
      </div>
    );
  }
  else{
    function onDocumentLoadSuccess(pdf) {
      const allPageNumbers = []; // array of numbers
      for (let p = 1; p < pdf.numPages + 1; p++) {
        allPageNumbers.push(p);
      }
      setAllPageNumbers(allPageNumbers);
  
      setOuterWidth(document.getElementById("pdf-container").offsetWidth);
    }
  
    // block right click for not downloading pdf images
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    
    var sourcePath = "";
    if (file == undefined) {
      history.push("/");
    }
  
    if (file.includes("http")){
      sourcePath = file;
    } else {
      sourcePath = atob(file);
    } 
    const additionalDocTitles = [];
    const additionalDocUrls = [];
    var pdfBaslik ="";
    if(sourcePath.includes('&&&'))
    {
      const params = sourcePath.split('&&&');
      for (let param of params) {
          if (param.startsWith("EkDokumanBaslik=")) {
              const value = param.split("EkDokumanBaslik=")[1];
              const decodedValue = value ? decodeURIComponent(value) : "Döküman";
              additionalDocTitles.push(decodedValue);
          }
          if (param.startsWith("DosyaUrl=")) {
              const value = param.split("DosyaUrl=")[1];
              if(value.includes("?url="))
              {
                let value2 = value.split("?url=")[1];
                if (value2.includes(".pdf")) {
                  value2 = value2.split(".pdf")[0] + ".pdf";
                }
                additionalDocUrls.push(value2);
              }
              else{
                additionalDocUrls.push(value);
              }
          }
          if (param.startsWith("PdfBaslik=")) {
            pdfBaslik = param.split("PdfBaslik=")[1];
          }
      }
      const pdfBaslikIndex = sourcePath.indexOf('&&&PdfBaslik');
      sourcePath = pdfBaslikIndex !== -1 ? sourcePath.substring(0, pdfBaslikIndex) : sourcePath;
    }

    if(location && location.pathname)
    { 
      tatsApiCall(
        "/UserLibrary/GetUserBooks",
        null,
        null,
        HTTP.GET,
        null,
        true
      ) .then(x => {
        var isBookShow = false;
        const parts = location.pathname.split("/");
        const lessonId = parts[4];
        x.data.productIds.forEach((item) => {
          if(item.id == lessonId)
          {
            isBookShow = true;
          }
        })
        if(isBookShow !== true) 
        {
          window.location.href = "/dijitalkitap/books";
        }
      })
      .catch(error => {
        console.error(error);
      });      
    }  

    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/dijitalkitap",
      },
      {
        title: "Kütüphanem",
        url: "/dijitalkitap/books",
      },
      {
        title: decodeURIComponent(pdfBaslik),
        url: "/dijitalkitap/book/lessons/"+ id,
      },
      {
        title: "Dijital Kitap",
        active: true
      },
    ];


    if (sourcePath.includes("fliphtml5")){
     var firstColonIndex = sourcePath.indexOf(":");
     if (firstColonIndex > 0 && sourcePath.substring(0, firstColonIndex) !== "https") {
        sourcePath = "https:" + sourcePath.substring(firstColonIndex + 1);
      }

      return (
        <div className="row">
          <div className="col-12 p-xs-0">
            <Breadcrumb items={breadcrumbs} />
          </div>

          <div
            id="pdf-container"
            style={{
              border: "none",
              width: "100%",
              padding: `${CONTAINER_PADDING}px`,
            }}
          >
              
          <div className="d-flex flex-column">
            <iframe 
                style={{ 
                  border: "none",
                  display: "flex",
                  width: "110%",
                  height: "800px"
                  }}
                src={sourcePath}
                seamless='seamless'
                allowtransparency='true' 
                allowFullScreen='true'
            ></iframe>
            <div
              className="text-center" style={{marginTop:'0px',marginBottom:'25px'}}>
                <span>
                  {decodeURIComponent(pdfBaslik)}
                </span>
            </div>
            <div className="row">
              {additionalDocUrls.map((item, i) => (
                <div
                  key={i}
                  className="col-4 col-sm-4 col-md-2 mt-2 mb-2 text-center"
                >
                  <a
                    href={item}
                    download
                  >
                    <Icon icon="iPdfAdditionalBookSvg" />
                  </a>
                  <div>
                    <span>
                      {additionalDocTitles[i]}
                    </span>
                  </div>
                </div>
              ))}
            </div>    
          </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="pdf-container"
          style={{
            border: "none",
            display: "flex",
            width: "100%",
            padding: `${CONTAINER_PADDING}px`,
          }}
        >
          <TransformWrapper
            limitToBounds={false}
            minScale={0.5}
            initialScale={1.0}
            wheel={{
              disabled: true,
            }}
            panning={{
              disabled: true,
            }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className="d-flex flex-column">
                <div className="d-flex mb-3 justify-content-between pdf__viewer">
                  <div className="d-flex">
                    <Link
                      className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
                      to={location.pathname.slice(0,location.pathname.length-5)}
                    >
                      Kitaba Geri Dön
                    </Link>
                  </div>
                  <div className="pdf__viewer-buttons">
                    <button
                      className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
                      onClick={() => {
                        zoomIn();
                      }}
                    >
                      Yakınlaştır
                    </button>
                    <button
                      className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
                      onClick={() => {
                        resetTransform();
                      }}
                    >
                      Sıfırla
                    </button>
                    <button
                      className="btn btn-link btn-primary fs-14 border-radius-8 font-weight-600 text-white text-capitalize mr-2"
                      onClick={() => {
                        zoomOut();
                      }}
                    >
                      Uzaklaştır
                    </button>
                  </div>
                </div>
    
                <TransformComponent className="relative">
                  <div
                    id="pdf-container"
                    style={{
                      border: "none",
                      display: "flex",
                      width: "100%",
                      borderRadius: "3px",
                      backgroundColor: "gray",
                    }}
                  >
                    <Document
                      file={sourcePath} // pdf file address
                      onLoadSuccess={onDocumentLoadSuccess}
                      renderMode="svg" // geç yüklemeye sebep oluyor ama küçük fontlarda görüntü problemi olmaması için eklendi
                    >
                      <div
                        style={{
                          maxHeight: `${PAGE_MAX_HEIGHT}px`,
                          overflowY: "scroll", // vertical scroll
                          overflowX: "hidden", // block horizontal scroll
                          border: "2px solid lightgray",
                          borderRadius: "5px",
                        }}
                      >
                        {allPageNumbers
                          ? allPageNumbers.map((pn) => (
                              <Page
                                key={`page-${pn}`}
                                width={outerWidth - CONTAINER_PADDING * 2}
                                pageNumber={pn}
                                renderAnnotationLayer={true}
                              />
                            ))
                          : undefined}
                      </div>
                    </Document>
                  </div>
                </TransformComponent>
                <div
                className="text-center" style={{margin:'25px'}}>
                  <span>
                    {decodeURIComponent(pdfBaslik)}
                  </span>
                </div>
                
                <div className="row">
                  {additionalDocUrls.map((item, i) => (
                    <div
                      key={i}
                      className="col-4 col-sm-4 col-md-2 mt-2 mb-2 text-center"
                    >
                      <a
                        href={item}
                        download
                      >
                        <Icon icon="iPdfAdditionalBookSvg" />
                      </a>
                      <div>
                        <span>
                          {additionalDocTitles[i]}
                        </span>
                      </div>
                    </div>
                  ))}
            </div>    
          </div>
        )}
          </TransformWrapper>
        </div>
      );
    } 
  };
  }

const mapStateToProps = ({ ProductReducer, AccountReducer, StudyReducer }) => ({
  user: AccountReducer.user,
});
const mapDispatchToProps = {
  loginRequired: actions.loginRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PdfViewer));
