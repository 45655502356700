import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Turnstile from "react-turnstile";

//import PropTypes from "prop-types";
import AddressForm from "../Account/AddressForm";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  getCoins,
  getStores,
  calculateProductPrice,
} from "../../routes/actions/Product";
import { toast } from "react-toastify";
import {
  SearchStore,
  Button,
  SelectButton,
  CoinItem,
  Modal,
  PaymentModal,
  Masterpass3D,
  Masterpass,
  ShoppingButton,
} from "../../components";
import CloseButton from "../../components/Button/Close";
import {
  fetchPayment,
  getContracts,
  getAddresses,
  getShippingOptions,
  getOrder,
  createAddress,
  updateAddress,
  updateOrderAddresses,
  getCardInfo,
  pay,
  indirimUygula,
  indirimVazgec,
  payCoin,
  updateMasterpassNumber,
  pageVisited,
  deleteAddress,
  getMasterpassTokens,
  payBkm,
} from "../../routes/actions/Account";
import {
  endPoint,
  keys,
  paymentType,
  turnstileSiteKeys,
} from "../../services/constants";
import { contractHelper, splitPrice, replaceDotWithComma } from "../../helpers";
import { formatDate } from "../../helpers/formatDatetime";
import DeliveryAddress from "./DeliveryAddress";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../components/Icon/Index";
//import CreditCard from "./CreditCard";
//import { garantiPayLogo } from '../../assets/images/icons'
import { deleteAllItemInBasket } from "../../routes/actions/Product";
// import { getTaxonomies } from "../../routes/actions/Product";
import storage from "../../services/storage";

const GetCheapestShipping = (shippingOptions) => {
  let minPrice = Math.min.apply(
    null,
    shippingOptions.map((item) => item.price)
  );

  let selectedShippingOption = shippingOptions.find(
    (x) => x.price === minPrice
  );

  return selectedShippingOption?.id || 1;
};

const ShippingOptions = ({
  shippingOptions,
  order,
  selectedShippingOptions,
  changeShipping,
  paymentMethod,
}) => {
  const calculatePrice = (selectedShippingOption) => {
    let price = 0;
    if (selectedShippingOption && order) {
      if (order.itemTotal >= selectedShippingOption.minimum) {
        price = 0;
      } else {
        price = selectedShippingOption.price;
      }
    }

    return price;
  };
  // const calculateDoorPaymentPrice = (selectedShippingOption) => {
  //   let doorPaymentPrice = 0;
  //   if (selectedShippingOption && order) {
  //     if (paymentMethod === paymentType.DoorPayment) {
  //       if (
  //         selectedShippingOption.doorPaymentMinimum >= 0 &&
  //         selectedShippingOption.doorPaymentDiscountedMinimum >= 0 &&
  //         selectedShippingOption.doorPaymentDiscountedPrice >= 0 &&
  //         order.itemTotal + order.taxTotal >=
  //           selectedShippingOption.doorPaymentDiscountedMinimum
  //       ) {
  //         doorPaymentPrice = selectedShippingOption.doorPaymentDiscountedPrice;
  //       } else {
  //         if (
  //           selectedShippingOption.doorPaymentMinimum >= 0 &&
  //           selectedShippingOption.doorPaymentOptionFee >= 0
  //         ) {
  //           doorPaymentPrice = selectedShippingOption.doorPaymentOptionFee;
  //         } else {
  //           doorPaymentPrice = 0;
  //         }
  //       }
  //     }
  //   }
  // };

  return shippingOptions.length == 0 ? (
    <div></div>
  ) : (
    <div className="d-flex cargo-container flex-wrap align-items-center justify-content-start b-dedede border-radius-8">
      <ul className="m-0 p-0">
        {shippingOptions.map((s, i) => {
          if (
            paymentMethod === paymentType.DoorPayment &&
            s.doorPaymentMinimum < 0
          ) {
            return;
          }

          const isSelected = s.id === selectedShippingOptions;
          let price = calculatePrice(s);
          // let doorPaymentPrice = calculateDoorPaymentPrice(s);
          return (
            <li>
              <label className="radio-container w-100 fs-13" key={i}>
                <span className="font-weight-600 cargo-text">
                  {s.name}{" "}
                  <span className="font-weight-500">
                    ({price === 0 ? `Ücretsiz` : `${price.toFixed(2)} ₺`})
                  </span>
                </span>
                <input
                  type="radio"
                  name="cargo"
                  onChange={() => changeShipping(s.id, s)}
                  checked={isSelected}
                />
                <span className="radio-checkmark" />
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const emptyAddress = {
  firstname: "",
  lastname: "",
  mobile: "",
  title: "",
  id: 0,
  city: {
    id: 0,
  },
  town: {
    id: 0,
  },
};

const paymentMethods = [
  { value: 1, title: "Kredi Kartı" },
  { value: 2, title: "Havale / EFT" },
  { value: 3, title: "Kapıda Ödeme" },
  { value: 4, title: "Jetonla Öde" },
  { value: 5, title: "BKM Express" },
];

const Contract = ({ contract, user, order, shippingInfo }) => {
  let html = contract.content;
  if (user && order) {
    html = contractHelper.fillContractFields(
      html,
      contractHelper.updateFields(contract.contractKeys, {
        user,
        order,
        shippingInfo,
      })
    );
  }
  return (
    <div
      className="card-body pt-0 fs-14"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

const formatPrice = (item) => {
  const price = splitPrice(item);

  return (
    <>
      {formatNumber(price[0])},<small>{price[1]}₺</small>
    </>
  );
};

Number.prototype.applyInterest = function applyInterest(rate) {
  return this * ((100 + rate) / 100);
};

Number.prototype.applyPureInterest = function applyPureInterest(rate) {
  return this * rate / 100;
};

const formatPrice2 = (item) => {
  const price = splitPrice(item);

  return formatNumber(price[0]) + "," + price[1];
};
const formatNumber = (number) => {
  if (isNaN(number)) return number;

  const formattedNumber = new Intl.NumberFormat("tr-TR").format(number);
  return formattedNumber;
};

class Payment extends Component {
  state = {
    isSame: true,
    paymentType: "CreditCard",
    payJetonAndCard: false,
    show: false,
    show3DSecure: false,
    title: "",
    paymentMethod: paymentType.CreditCard,
    collapse0: false,
    collapse1: false,
    collapse2: false,
    shippingOption: 1,
    address: { ...emptyAddress },
    isSecure: false,
    acceptTerms: true,
    card: {},
    shippingPrice: 0,
    isPaying: false,
    showGarantiPay: false,
    innerHtml: false,
    showModal: null,
    saveMasterpass: false,
    purchaseMasterpass: false,
    payment: null,
    startMPRegistration: false,
    bankName: null,
    masterpassBin: "",
    masterpass3D: false,
    masterpass3DURL: "",
    secure3DToken: "",
    saveAfter3D: false,
    showPriceDetail: false,
    discountCode: "",
    toplamKuponIndirimi: 0.0,
    couponIsReserved: false,
    isListIndirim: false,
    couponExpireDate: "",
    couponOrderId: 0,
    indirimDetaylari: {},
    cardInfo: {
      installments: [
        {
          installment: 1,
          rate: 0,
        },
      ],
      bankName: "",
      isFull3D: false,
    },
    installment: 1,
    installmentRate: 0,
    token: null,
    turnstileWidgetId: "",
  };

  async componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.bkmexpress.com.tr/v1/javascripts/bex.js";
    // script.src = "https://preprod-js.bkmexpress.com.tr/v1/javascripts/bex.js";
    script.async = true;
    document.body.appendChild(script);

    const { id, type } = this.props.match.params;
    const { paid } = await this.props.fetchPayment(id, type);

    if (type === "sepet") {
      if (paid) {
        this.props.history.push("/");
        toast.error("Bu siparişin ödemesi zaten yapılmış.");
      } else {
        let shippingPrice = 0;
        let selectedShippingOption = this.props.shippingOptions.find(
          (x) => x.id === this.state.shippingOption
        );

        if (selectedShippingOption && this.props.order) {
          shippingPrice =
            this.props.order.itemTotal >= selectedShippingOption.minimum
              ? 0
              : selectedShippingOption.price;
        }

        let initialState = {
          isSame: !(
            this.props.order.deliveryAddress &&
            this.props.order.deliveryAddress.id !==
              this.props.order.billAddress.id
          ),
          shippingPrice,
        };

        this.setState(initialState);

        // if (typeof window !== "undefined") {
        //   // gtm - datalayer
        //   if (window.dataLayer != null) {
        //     const products = this.props.order.lines.map((l) => {
        //       return {
        //         id: l.productId,
        //         quantity: l.quantity,
        //         name: l.product.name,
        //         price: l.total,
        //       };
        //     });
        //     window.dataLayer.push({
        //       event: "checkout",
        //       ecommerce: {
        //         checkout: {
        //           actionField: { step: 2, option: "Payment Page" },
        //           products: products,
        //         },
        //       },
        //     });
        //   }
        // }
        this.setState({ couponOrderId: id });
        var discountData = storage.getItem(keys.CouponDiscount);
        discountData = JSON.parse(discountData);
        const now = new Date();
        const expireDate = new Date(discountData?.couponExpireDate);
        if (expireDate > now) {
          this.setState({ discountCode: discountData?.code || "" });
          this.discountApply();
        } else {
          storage.setItem(keys.CouponDiscount, null);
          this.props.getOrder(id);
          this.props.pageVisited(id, this.state.discountCode, this.state.couponIsReserved);
        }
      }
    }

    this.props.getCoins();
    this.setState({
      shippingOption: GetCheapestShipping(this.props.shippingOptions),
    });

    this.props.getShippingOptions();
  }

  setIndirimKodu = (code) => {
    this.setState({ discountCode: code.toLocaleUpperCase('tr-TR') });
  };

  initiateMasterpass3D = (url, token) => {
    this.setState({ masterpass3D: true, masterpass3DURL: url });
  };

  on3DSuccess = (token, save) => {
    this.setState({ secure3DToken: token, saveAfter3D: !!save });
  };

  closeMPModalExplicitly = () => {
    this.setState({
      isPaying: false,
      payment: null,
      masterpass3D: false,
      masterpass3DURL: "",
    });
  };

  closeMPModalImplicitly = () => {
    this.setState({ masterpass3D: false, masterpass3DURL: "" });
  };

  masterpassStateChange = (state) => {
    this.setState({ saveMasterpass: state });
  };

  purchaseMasterpassStateChange = (state, bin) => {
    const newState = {
      purchaseMasterpass: state,
      masterpassBin: state ? bin : "",
      saveMasterpass: false,
    };
    if (state) {
      newState.card = {};
    }
    this.setState(newState);
  };

  handleOnChange = () => {
    let newState = { isSame: !this.state.isSame };
    if (newState.isSame) {
      const order = this.props.order;
      this.props.updateOrderAddresses(
        Object.assign(order, {
          billAddress: order.deliveryAddress,
        })
      );
    }
    this.setState(newState);
  };

  toggleShow = () => {
    this.setState({ showPriceDetail: !this.state.showPriceDetail });
  };

  handleCreditCardChange = async (formData) => {
    this.setState({ card: formData });
    if (
      formData.creditCardNumber &&
      formData.creditCardNumber.replace(/\s/g, "").length > 5
    ) {
      let bin = formData.creditCardNumber.replace(/\s/g, "").substring(0, 8);
      if (bin !== this.state.lastCheckedBin) {
        let cardInfo = await this.props.getCardInfo(bin);
        if (cardInfo.success) {
          this.setState({
            cardInfo: cardInfo.data,
            installment: cardInfo.data.installments[0].installment,
            installmentRate: cardInfo.data.installments[0].rate,
          });
          this.setState({
            lastCheckedBin: bin,
          });
          if (cardInfo.data.isFull3D) this.toggle3D(cardInfo.data.isFull3D);
        }
      }
    } else if (
      !formData.creditCardNumber ||
      formData.creditCardNumber.replace(/\s/g, "").length < 6
    ) {
      this.setState({
        cardInfo: {
          installments: [
            {
              installment: 1,
              rate: 0,
            },
          ],
          bankName: "",
          isFull3D: false,
        },
        installment: 1,
        installmentRate: 0,
      });
    }
  };

  deliveryAddressChange = async (value) => {
    const order = this.props.order;
    let newOrder = {};
    if (this.state.isSame) {
      newOrder.deliveryAddress = value;
      newOrder.billAddress = value;
    } else {
      newOrder.deliveryAddress = value;
    }
    const { success, error } = await this.props.updateOrderAddresses(
      Object.assign(order, newOrder)
    );
    if (!success) {
      toast.error(error);
    } else {
      this.forceUpdate();
    }
  };

  deleteAddress = async (address) => {
    const confirmed = window.confirm(
      `(${address.title}) adresi silinecek onaylıyor musunuz?`
    );

    if (confirmed) {
      const { success, error } = await this.props.deleteAddress(address);

      this.setState({ address: {}, show: false, title: "" }, () => {
        if (success) {
          toast.success(`${address.title} silindi`);
        } else {
          toast.error(error.message);
        }
      });
    }
  };
  billAddressChange = async (value) => {
    const order = this.props.order;
    const { success, error } = await this.props.updateOrderAddresses(
      Object.assign(order, {
        billAddress: value,
      })
    );

    if (!success) {
      toast.error(error);
    } else {
      this.forceUpdate();
    }
  };

  createAddress = async (address) => {
    await this.props.createAddress(address);
    this.setState({ show: false });
    this.deliveryAddressChange(this.props.addresses[0]);
  };

  updateAddress = async (address) => {
    await this.props.updateAddress(address);
    this.setState({ show: false });
  };

  toggleCollapse0 = () => {
    this.setState({ collapse0: !this.state.collapse0 });
  };

  toggleCollapse1 = () => {
    this.setState({ collapse1: !this.state.collapse1 });
  };

  toggleCollapse2 = () => {
    this.setState({ collapse2: !this.state.collapse2 });
  };

  handleClose = () => {
    this.setState({ showModal: null });
  };

  handleShow = (id, e) => {
    e.preventDefault();
    this.setState({ showModal: id });
  };

  discountApply = async () => {
    const basketGuid = storage.getItem(keys.Basket);
    const user = storage.getItem(keys.User);
    const indirimKupon = {
      basketGuid,
      email: JSON.parse(user).email,
      code: this.state.discountCode,
    };
    const indirim = await this.props.indirimUygula(indirimKupon);
    if (indirim.data.status) {
      this.props.pageVisited(this.state.couponOrderId, indirim.data.data.indirimKodu, true);
      await this.props.getOrder(this.state.couponOrderId, true, indirim.data.status, indirim.data.data.indirimKodu, indirimKupon.email);
      this.setState({
        toplamKuponIndirimi: indirim.data.data.toplamIndirimTutari,
        couponIsReserved: true,
        isListIndirim: indirim.data.data.isListIndirim,
        couponExpireDate: indirim.data.data.expireDate,
      });
      storage.setItem(
        keys.CouponDiscount,
        JSON.stringify({
          code: indirim.data.data.indirimKodu,
          couponIsReserved: true,
          isListIndirim: indirim.data.data.isListIndirim,
          couponExpireDate: indirim.data.data.expireDate,
          toplamKuponIndirimi: indirim.data.data.toplamIndirimTutari,
          indirimDetaylari: indirim.data.data.indirimDetaylari,
        })
      );
      
      toast.success(indirim.data.message);
    } else {
      storage.setItem(keys.CouponDiscount, null);
      this.setState({
        toplamKuponIndirimi: 0.0,
        couponIsReserved: false,
        isListIndirim: false,
        discountCode: "",
      });
      toast.error(indirim.data.message);
    }
  };


  discountCancel = async () => {
    const user = storage.getItem(keys.User);
    const indirimKupon = {
      email: JSON.parse(user).email,
      code: this.state.discountCode,
    };
    const sonuc = await this.props.indirimVazgec(indirimKupon);
    if (sonuc.data.status) {
      this.props.pageVisited(this.state.couponOrderId);
      this.props.getOrder(this.state.couponOrderId);
      storage.setItem(keys.CouponDiscount, null);
      this.setState({
        toplamKuponIndirimi: 0.0,
        couponIsReserved: false,
        isListIndirim: false,
        discountCode: "",
      });

      toast.success(sonuc.data.message);
    } else {
      this.props.pageVisited(this.state.couponOrderId);
      this.props.getOrder(this.state.couponOrderId);
      storage.setItem(keys.CouponDiscount, null);
      this.setState({
        toplamKuponIndirimi: 0.0,
        couponIsReserved: false,
        isListIndirim: false,
        discountCode: "",
      });
      toast.error(sonuc.data.message);
    }
  };

  clickApply = () => {
    if (this.state.couponIsReserved == true) this.discountCancel();
    else this.discountApply();
  };

  handleChangeShipping = (id, shippingOption) => {
    const _state = {
      shippingOption: id,
    };

    if (
      this.state.paymentMethod === paymentType.DoorPayment &&
      shippingOption.doorPaymentMinimum < 0
    ) {
      _state.paymentMethod = paymentType.CreditCard;
    }

    this.setState(_state);
  };

  toCard = ({ expireDate, creditCardNumber, ...rest }) => {
    const values = expireDate ? expireDate.split("/") : ["", ""];
    const tempCreditCardNumber = (creditCardNumber || "").split(" ").join("");
    return {
      creditCardNumber: tempCreditCardNumber,
      expireMonth: values[0],
      expireYear: values[1],
      ...rest,
    };
  };

  validateCard = () => {
    try {
      const { card } = this.state;
      const cardModel = this.toCard(card);
      if (cardModel.creditCardNumber.length !== 16) {
        return false;
      }
      if (cardModel.CVC.length !== 3) {
        return false;
      }
      if (
        Number(cardModel.expireMonth) > 12 ||
        Number(cardModel.expireMonth) < 1
      ) {
        return false;
      }
      if (Number(cardModel.expireYear) < 22) {
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  };

  handle3d = async (payment, cardInfo) => {
    this.setState({
      show3DSecure: true,
      payment: payment,
      innerHtml: cardInfo.innerHtml,
    });
  };

  setInstallment = (installment, installmentRate = 0) => {
    this.setState({ installment, installmentRate });
  };

  createPayment = (order, paymentMethod) => {
    const { card, shippingOption, installment } = this.state;
    let payment = {
      ...this.toCard(card),
      orderNumber: order.orderNumber,
      name: order.deliveryAddress.firstname,
      total: (
        order.itemTotal +
        order.taxTotal -
        (this.state.toplamKuponIndirimi || 0)
      ).applyInterest(this.state.installmentRate),
      purchaseCount: installment,
      origin: endPoint,
      paymentMethod: paymentMethod,
      shippingOption,
      saveMasterpass:
        (paymentMethod === paymentType.CreditCard ||
          (paymentMethod === paymentType.Coin && this.state.payJetonAndCard)) &&
        this.state.saveMasterpass,
      purchaseMasterpass:
        (paymentMethod === paymentType.CreditCard ||
          (paymentMethod === paymentType.Coin && this.state.payJetonAndCard)) &&
        this.state.purchaseMasterpass,
      couponIsReserved: this.state.couponIsReserved,
      isListIndirim: this.state.isListIndirim,
      couponCode: this.state.discountCode,
    };

    return payment;
  };

  createPackPayment = (obectId, paymentMethod) => {
    const { coins, user } = this.props;
    const { card, installment } = this.state;
    const coin = (coins || []).find((p) => p.id === obectId);
    let payment = {
      ...this.toCard(card),
      orderNumber: `J${coin.id}J${user.id}J${new Date().getTime()}`,
      name: `${user.firstname} ${user.lastname}`,
      total: coin.price,
      purchaseCount: installment, //FIXME taksitler nereden gelecek? sanki böyle bir yer eksik :)
      origin: endPoint,
      paymentMethod: paymentMethod,
      saveMasterpass:
        (paymentMethod === paymentType.CreditCard ||
          (paymentMethod === paymentType.Coin && this.state.payJetonAndCard)) &&
        this.state.saveMasterpass,
      purchaseMasterpass:
        (paymentMethod === paymentType.CreditCard ||
          (paymentMethod === paymentType.Coin && this.state.payJetonAndCard)) &&
        this.state.purchaseMasterpass,
      couponIsReserved: this.state.couponIsReserved,
      isListIndirim: this.state.isListIndirim,
      couponCode: this.state.discountCode,
    };
    return payment;
  };

  handlePayment = async () => {
    const {
      paymentMethod,
      acceptTerms,
      shippingPrice,
      isPaying,
      toplamKuponIndirimi,
      couponExpireDate,
      //showGarantiPay,
    } = this.state;
    const { order, wallet, match } = this.props;
    const isOrder = match.params.type === "sepet";
    const obectId = parseInt(match.params.id);
    const now = new Date();
    const expireDate = new Date(couponExpireDate);
    var error = false;
    var errorMessage = "";
    if (isOrder && order.lines.length < 1) {
      errorMessage = "Sipariş edilebilecek ürün bulunmamaktadır.";
      error = true;
    } else if (
      isOrder &&
      (order.deliveryAddress.id < 2 || order.billAddress.id < 2)
    ) {
      errorMessage = "Lütfen adres seçimi yapınız.";
      error = true;
    } else if (isPaying === true) {
      errorMessage = "Lütfen ödeme işleminin bitmesini bekleyiniz.";
      error = true;
    } else if (acceptTerms !== true) {
      errorMessage = "Lütfen sözleşmeyi onaylayınız.";
      error = true;
    } else if (expireDate < now) {
      errorMessage = "Kupon rezerve süreniz dolmuştur.";
      error = true;
    }
    if (error === true) {
      toast.error(errorMessage);
      return;
    }

    let payment = isOrder
      ? this.createPayment(order, paymentMethod)
      : this.createPackPayment(obectId, paymentMethod);

    payment.cfToken = this.state.token;
    payment.isListIndirim = this.state.isListIndirim;

    if (
      paymentMethod === paymentType.CreditCard ||
      (paymentMethod === paymentType.Coin &&
        order.itemTotal + order.taxTotal + shippingPrice - toplamKuponIndirimi >
          (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0))
    ) {
      if (paymentMethod === paymentType.Coin && !this.state.payJetonAndCard) {
        toast.error("Hesabınızda yeterli jeton bulunmamaktadır.");
        return;
      }

      if (
        this.validateCard() === false &&
        !(
          (payment.paymentMethod === paymentType.CreditCard ||
            (payment.paymentMethod === paymentType.Coin &&
              this.state.payJetonAndCard)) &&
          payment.purchaseMasterpass
        )
      ) {
        toast.error("Kart bilgilerinin girilmesi zorunludur.");
        return;
      }

      if (
        paymentType.CreditCard &&
        !payment.purchaseMasterpass &&
        !payment.saveMasterpass
      ) {
        let cardInfo = await this.props.getCardInfo(
          payment.creditCardNumber.replace(/\s/g, "").substring(0, 8)
        );

        if (this.state.isSecure) {
          return this.handle3d(payment, cardInfo);
        } else if (cardInfo.data.secure3D === true || cardInfo.data.isFull3D) {
          toast.error("bu kartla sadece 3d ile ödeme yapabilirsiniz.");
          return;
        }
      }
    }

    this.setState({ isPaying: true }, async () => {
      let result;
      if (isOrder) {
        if (
          payment.paymentMethod === paymentType.CreditCard &&
          payment.purchaseMasterpass
        ) {
          payment.total = (this.calculateTotalPrice() * 100).toFixed(0);
          payment.creditCardNumber = this.state.masterpassBin;
          this.setState({ payment: payment });
          return;
        } else if (
          payment.paymentMethod === paymentType.Coin &&
          this.state.payJetonAndCard &&
          payment.purchaseMasterpass
        ) {
          payment.total = (
            (this.calculateTotalPrice() -
              ((this.props.wallet?.coinTL ?? 0) +
                (this.props.wallet?.bonusTL ?? 0))) *
            100
          ).toFixed(0);
          payment.creditCardNumber = this.state.masterpassBin;
          this.setState({ payment: payment });
          return;
        } else if (
          payment.paymentMethod === paymentType.CreditCard &&
          payment.saveMasterpass
        ) {
          payment.total = (this.calculateTotalPrice() * 100).toFixed(0);
          this.setState({ payment: payment });
          return;
        } else if (
          payment.paymentMethod === paymentType.Coin &&
          this.state.payJetonAndCard &&
          payment.saveMasterpass
        ) {
          payment.total = (
            (this.calculateTotalPrice() -
              ((this.props.wallet?.coinTL ?? 0) +
                (this.props.wallet?.bonusTL ?? 0))) *
            100
          )
            .applyInterest(this.state.installmentRate)
            .toFixed(0);
          this.setState({ payment: payment });
          return;
        } else if (payment.paymentMethod === paymentType.BkmExpress) {
          const data = (
            await this.props.payBkm(obectId, {
              ...payment,
              bkmTicket: "ticketNew",
            })
          ).data;
          if (data.success) {
            const ticket = JSON.parse(data.ticket);
            const that = this;
            window.Bex.init(ticket, "modal", {
              container: "payment-dropin",
              buttonSize: [135, 70],
              skipButton: true,
              onCancel: async function () {
                const newData = (
                  await that.props.payBkm(obectId, {
                    ...payment,
                    bkmTicket: "ticketCancel",
                  })
                ).data;
                const newTicket = JSON.parse(newData.ticket);
                window.Bex.refresh(newTicket);
                that.setState({
                  isPaying: false,
                });
              },
              onComplete: function (status) {
                if (status) {
                  toast.success("Ödeme işlemi başarıyla gerçekleşmiştir.");
                  that.props.deleteAllItemInBasket();
                } else {
                  toast.error("Ödeme işlemi başarısız.");
                }

                that.setState(
                  {
                    isPaying: false,
                  },
                  () => {
                    that.success();
                  }
                );
              },
            });
          }

          // const trimData =
          // result =
          //
          // this.completeOrder(result);
        } else {
          result = await this.props.pay(obectId, payment);
          this.completeOrder(result);
        }
      } else {
        
        if (payment.purchaseMasterpass) {
          payment.total = (payment.total.applyInterest(this.state.installmentRate) * 100).toFixed(0);
          payment.creditCardNumber = this.state.masterpassBin;
          payment.isPack = true;
          this.setState({ payment: payment });
          return;
        } else if (payment.saveMasterpass) {
          payment.total = (payment.total.applyInterest(this.state.installmentRate) * 100).toFixed(0);
          payment.isPack = true;
          this.setState({ payment: payment });
          return;
        } else {
          result = await this.props.payCoin(parseInt(obectId), payment);
          this.completeOrder(result);
        }
      }
    });
  };

  payWithMasterpass = async (data) => {
    var id = parseInt(this.props.match.params.id);
    const payment = { ...this.state.payment, MasterpassData: data };
    const result = payment.isPack
      ? await this.props.payCoin(id, payment)
      : await this.props.pay(id, payment);
    result.mp = true;
    this.completeOrder(result);
  };

  payAndSaveMasterpass = async (data) => {
    var id = parseInt(this.props.match.params.id);
    const payment = { ...this.state.payment, MasterpassData: data };
    const result = payment.isPack
      ? await this.props.payCoin(id, payment)
      : await this.props.pay(id, payment);
    this.completeMPRegistration(result);
  };

  cancelMpProcess = () => {
    this.setState({ isPaying: false, payment: null, secure3DToken: "" });
  };

  completeMPRegistration = (result) => {
    if (result.success === true) {
      if (result.data.status === true) {
        this.setState({
          isPaying: false,
          startMPRegistration: true,
          bankName: result.data.bankName,
        });
      } else {
        this.setState(
          {
            isPaying: false,
            payment: null,
            startMPRegistration: false,
            secure3DToken: "",
          },
          () => {
            toast.error(result.data.message);
          }
        );
      }
    } else {
      this.setState(
        {
          isPaying: false,
          payment: null,
          startMPRegistration: false,
          secure3DToken: "",
        },
        () => {
          toast.error(
            (result.error.response && result.error.response.data) ||
              "bir hata oluştu"
          );
        }
      );
    }
  };

  mpRegistrationCompleted = async () => {
    await this.props.updateMasterpassNumber(
      `9${this.props.user.mobile.replace(/ /g, "")}`
    );

    this.setState({
      isPaying: false,
      payment: null,
      bankName: null,
      startMPRegistration: false,
      saveMasterpass: false,
      secure3DToken: "",
    });

    this.success(true);
  };

  completeOrder = (result) => {
    this.setState(
      {
        isPaying: false,
        payment: null,
        bankName: null,
        startMPRegistration: false,
        secure3DToken: "",
      },
      () => {
        const GA4Products = [];
        let products = this.props.order.lines.filter((p) => p.price != 0);
        products.forEach((product, index) => {
          let productsTaxonIds = Array.from(
            this.props.products
              .filter((p) => p.id == product.productId)[0]
              ?.taxons.filter((element) => typeof element === "number")
          );
          const productsTaxonSet = new Set(productsTaxonIds);
          let productTaxons = this.props.taxonomies
            ?.filter((t) =>
              t.taxons.some((tx) => productsTaxonIds.includes(tx.id))
            )
            .flatMap((t) => {
              return t.taxons.filter((taxon) => productsTaxonSet.has(taxon.id));
            });
          const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
          const productTopTaxon = this.props.taxonomies?.filter(
            (t) => t.id == productTopTaxonId
          );
          GA4Products.push({
            item_id: product.productId,
            item_name: product.product.name,
            coupon: discountData?.code || "",
            currency: "TRY",
            index: index.toString(),
            // discount:
            //   discountData?.indirimDetaylari.filter(
            //     (p) => p.productId === product.productId
            //   )[0].discountAmount || 0,
            discount: 0,
            item_category: productTaxons?.[0]?.name,
            item_category2: productTaxons?.[1]?.name,
            item_category3: productTaxons?.[2]?.name,
            item_category4: productTaxons?.[3]?.name,
            item_category5: productTaxons?.[4]?.name,
            item_variant: productTaxons?.[0]?.name ?? product.category,
            price: Number(calculateProductPrice(product).toFixed(2)),
            quantity: product.quantity,
            item_list_id: productTopTaxon?.[0]?.id,
            item_list_name: productTopTaxon?.[0]?.description,
          });
        });
        if (result.success === true) {
          if (result.data.status === true) {
            this.success(result.mp);
            var discountData = storage.getItem(keys.CouponDiscount);
            discountData = JSON.parse(discountData);
            let selectedShippingOption = this.props.shippingOptions.find(
              (x) => x.id === this.state.shippingOption
            );
            if (typeof window !== "undefined") {
              // gtm - datalayer
              if (window.dataLayer != null) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                  event: "add_shipping_info ",
                  ecommerce: {
                    shipping_tier: selectedShippingOption.name,
                    currency: "TRY",
                    value: Number(
                      (discountData
                        ? this.props.order?.total || 0 -
                          discountData?.toplamKuponIndirimi || 0
                        : this.props.order?.total || 0
                      ).toFixed(2)
                    ),
                    coupon: discountData?.code || "",
                    items: GA4Products,
                  },
                });
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                  event: "add_payment_info",
                  ecommerce: {
                    currency: "TRY",
                    value: Number(
                      (discountData
                        ? this.props.order?.total || 0 -
                          discountData?.toplamKuponIndirimi || 0
                        : this.props.order?.total || 0
                      ).toFixed(2)
                    ),
                    coupon: discountData?.code || "",
                    payment_type:
                      this.state.paymentMethod === paymentType.CreditCard ||
                      this.state.paymentMethod === paymentType.BkmExpress
                        ? "Credit Card"
                        : "Other",
                    items: GA4Products,
                  },
                });
                window.dataLayer.push({ ecommerce: null });
              }
            }
            storage.setItem(keys.CouponDiscount, null);
            this.setState({
              toplamKuponIndirimi: 0.0,
              couponIsReserved: false,
              isListIndirim: false,
            });
          } else {
            window.turnstile.reset(this.state.turnstileWidgetId);
            toast.error(result.data.message);
          }
        } else {
          window.turnstile.reset(this.state.turnstileWidgetId);
          if (result.error.status == 429 || result.error.status == 404) {
            toast.error(result.error.data);
          } else {
            toast.error(
              (result.error.response && result.error.response.data) ||
                "bir hata oluştu"
            );
          }
        }
      }
    );
  };

  success = (mp = false) => {
    const { match } = this.props;
    this.props.history.push(
      `/odeme/${match.params.type}/tamamlandi/${match.params.id}${
        mp ? "?mp=true" : ""
      }`
    );
    this.trackGA(mp);
  };

  trackGA = (mp) => {
    ReactGA.plugin.require("ecommerce");

    ReactGA.plugin.execute("ecommerce", "addTransaction", {
      id: `${this.props.order.orderNumber}`, // Sipariş Numarası
      affiliation: "dogrukaynak", // Mağaza Adı
      revenue: `${this.calculateTotalPrice().toFixed(2)}`, // Toplam Tutar
      tax: `${this.props.order.taxTotal.toFixed(2)}`,
      shipping: `${this.calculateShippingPrice().toFixed(2)}`,
      currencyCode: "TRY",
    });

    this.props.order.lines.forEach((basketItem) => {
      ReactGA.plugin.execute("ecommerce", "addItem", {
        id: `${this.props.order.orderNumber}`,
        sku: `${basketItem.product.id}`,
        name: `${basketItem.product.name}`,
        category: `${
          basketItem.product.class == 0
            ? basketItem.product.name
            : `${basketItem.product.class}. Sınıf`
        }`,
        price: `${(
          basketItem.price +
          basketItem.taxTotal / basketItem.quantity
        ).toFixed(2)}`,
        quantity: `${basketItem.quantity}`,
      });
    });

    ReactGA.plugin.execute("ecommerce", "send");
    ReactGA.plugin.execute("ecommerce", "clear");

    ReactGA.event({
      category: `${mp ? "Masterpass Payment" : "Payment"}`,
      action: `UserID: ${this.props.user.id} OrderID: ${this.props.order.orderNumber}`,
    });
  };

  handleAddClick = () => {
    this.setState({
      show: true,
      title: "Yeni Adres Kaydet",
      address: emptyAddress,
    });
  };

  handleEditClick = (address) => {
    this.setState({
      show: true,
      title: "Adresi Düzenle",
      address,
    });
  };
  /*
  calculateShippingPice = () => {
    let selectedShippingOption = this.props.shippingOptions.find(
      (x) => x.id === this.state.shippingOption
    );
    let shippingPrice = 0;
    if (selectedShippingOption && this.props.order) {
      shippingPrice =
        this.state.paymentMethod === paymentType.DoorPayment
          ? selectedShippingOption.doorPaymentOptionFee
          : this.props.order.itemTotal >= selectedShippingOption.minimum
          ? 0
          : selectedShippingOption.price;
    }

    return shippingPrice;
  };
*/
  calculateShippingPrice = () => {
    let price = 0;
    let selectedShippingOption = this.props.shippingOptions.find(
      (x) => x.id === this.state.shippingOption
    );
    selectedShippingOption.price = Math.min(
      ...this.props.shippingOptions.map((item) => item.price)
    );
    if (selectedShippingOption && this.props.order) {
      if (this.state.paymentMethod === paymentType.DoorPayment) {
        if (
          selectedShippingOption.doorPaymentMinimum >= 0 &&
          selectedShippingOption.doorPaymentDiscountedMinimum >= 0 &&
          selectedShippingOption.doorPaymentDiscountedPrice >= 0 &&
          this.props.order.itemTotal + this.props.order.taxTotal >=
            selectedShippingOption.doorPaymentDiscountedMinimum
        ) {
          price = selectedShippingOption.doorPaymentDiscountedPrice;
        } else {
          if (
            selectedShippingOption.doorPaymentMinimum >= 0 &&
            selectedShippingOption.doorPaymentOptionFee >= 0
          ) {
            price = selectedShippingOption.doorPaymentOptionFee;
          } else {
            price = 0;
          }
        }
      } else if (this.props.order.itemTotal >= selectedShippingOption.minimum) {
        price = 0;
      } else {
        price = selectedShippingOption.price;
      }
    }
    const isAllDigitalProduct = this.props.order.lines.every(
      (p) => p.isDigital
    );
    if (isAllDigitalProduct) {
      price = 0;
    }
    return price;
  };

  calculateTotalPrice = () => {
    let selectedShippingOption = this.props.shippingOptions.find(
      (x) => x.id === this.state.shippingOption
    );
    let shippingPrice = 0;
    if (
      selectedShippingOption &&
      this.props.order &&
      !this.props.order.lines.every((p) => p.isDigital)
    ) {
      shippingPrice =
        this.state.paymentMethod === paymentType.DoorPayment
          ? this.calculateShippingPrice()
          : this.props.order.itemTotal >= selectedShippingOption.minimum
          ? 0
          : selectedShippingOption.price;
    }

    return (
      this.props.order.itemTotal +
      this.props.order.taxTotal  -
      (this.state.toplamKuponIndirimi || 0) -
      (this.state.paymentMethod === paymentType.Coin ? 
      ((this.props.wallet?.coinTL ?? 0) +
        (this.props.wallet?.bonusTL ?? 0)) :
        0)
    ).applyInterest(this.state.installmentRate) + 
    shippingPrice +
    (this.state.paymentMethod === paymentType.Coin ? 
      ((this.props.wallet?.coinTL ?? 0) +
        (this.props.wallet?.bonusTL ?? 0)) :
        0);
  };

  toggle3D = (secure = false) => {
    this.setState({
      isSecure: secure,
    });
  };

  handleGarantiPay = () => {};

  render() {
    const OrderContracts = ({ contracts, shippingInfo }) => {
      return (
        <span className="w-100">
          {contracts.map((contract, i) => (
            <React.Fragment key={i}>
              <a
                href="# "
                className="fs-12 text-primary color-484848"
                style={{
                  lineHeight: "1rem",
                  fontWeight: 500,
                  textDecoration: "underline",
                }}
                onClick={(e) => this.handleShow(contract.contractId, e)}
              >
                {contract.contractName}
              </a>
              <span
                className="fs-12"
                style={{ lineHeight: "1rem", fontWeight: 500 }}
              >
                'nı
              </span>
              <Modal
                className="modal-container contract-modal non-modal-height overflow-scroll"
                show={this.state.showModal === contract.contractId}
                onCloseClick={this.handleClose}
              >
                <div className="modal-header border-0">
                  <CloseButton onClick={this.handleClose} />
                  <div className="modal-header bt-dedede:50">
                    <h5
                      className="modal-title font-weight-600 color-484848"
                      style={{ fontSize: 20 }}
                    >
                      {contract.contractName}
                    </h5>
                  </div>
                </div>
                <div className="modal-body">
                  <Contract
                    contract={contract}
                    user={user}
                    order={{
                      ...order,
                      paymentMethod: this.state.paymentMethod,
                    }}
                    shippingInfo={shippingInfo}
                  />
                </div>
              </Modal>
            </React.Fragment>
          ))}
        </span>
      );
    };
    const price = this.props.basket.reduce(
      (a, c) => {
        if (c.product?.getable) {
          a.productTotal += c.price * c.quantity;
          a.taxTotal += c.taxTotal * c.quantity;
        }
        return a;
      },
      { productTotal: 0, taxTotal: 0 }
    );
    const {
      coins,
      order,
      wallet,
      match,
      addresses,
      user,
      shippingOptions,
      contracts,
    } = this.props;
    const filteredOrders = contracts.filter((c) =>
      c.places.some((p) => p === "Order")
    );

    if (this.state.couponIsReserved) {
      order.itemTotal = order.baseItemTotal;
      order.taxTotal = order.baseTaxTotal;
    }

    const { showGarantiPay, cardInfo, installment } = this.state;
    const orderId = parseInt(match.params.id);
    const addressesOptions = addresses.map((item) => {
      item.label = item.title;
      item.value = item.id;
      return item;
    });
    const isDigitalCoin = match.params.type === "jeton";
    const coin = (coins || []).find((p) => p.id === orderId);
    let selectedShippingOption = shippingOptions.find(
      (x) => x.id === this.state.shippingOption
    );
    let showDoorPayment =
      selectedShippingOption &&
      selectedShippingOption.doorPaymentMinimum >= 0 &&
      order.itemTotal + order.taxTotal >=
        selectedShippingOption.doorPaymentMinimum;

    const hasDigitalProduct = this.props.order.lines.some((p) => p.isDigital);
    const isAllDigitalProduct = this.props.order.lines.every(
      (p) => p.isDigital
    );
    let shippingPrice = 0;
    let doorPaymentPrice = 0;
    const _calculateShippingPrice = () => {
      let price = 0;
      if (order.itemTotal >= selectedShippingOption.minimum) {
        price = 0;
      } else {
        price = selectedShippingOption.price;
      }
      if (isAllDigitalProduct) {
        price = 0;
      }
      return price;
    };
    const _calculateShippingDoorPrice = () => {
      let doorPaymentPrice = 0;
      if (this.state.paymentMethod === paymentType.DoorPayment) {
        if (
          selectedShippingOption.doorPaymentMinimum >= 0 &&
          selectedShippingOption.doorPaymentDiscountedMinimum >= 0 &&
          selectedShippingOption.doorPaymentDiscountedPrice >= 0 &&
          order.itemTotal + order.taxTotal >=
            selectedShippingOption.doorPaymentDiscountedMinimum
        ) {
          doorPaymentPrice = selectedShippingOption.doorPaymentDiscountedPrice;
        } else {
          if (
            selectedShippingOption.doorPaymentMinimum >= 0 &&
            selectedShippingOption.doorPaymentOptionFee >= 0
          ) {
            doorPaymentPrice = selectedShippingOption.doorPaymentOptionFee;
          } else {
            doorPaymentPrice = 0;
          }
        }
        return doorPaymentPrice;
      }
    };

    if (selectedShippingOption && order) {
      shippingPrice = _calculateShippingPrice();
      doorPaymentPrice = _calculateShippingDoorPrice();
    }
    const total =
      order.itemTotal -
      this.state.toplamKuponIndirimi +
      order.taxTotal +
      shippingPrice +
      (doorPaymentPrice ?? 0);
    const loaded =
      ((order &&
        order.id &&
        shippingOptions &&
        (isAllDigitalProduct || shippingOptions.length) &&
        addresses) ||
        (isDigitalCoin && coins && coins.length)) &&
      contracts &&
      contracts.length;

      var totalAmount =
            (this.state.paymentMethod === paymentType.Coin
              ? order.itemTotal +
                  order.taxTotal +
                  shippingPrice -
                  this.state.toplamKuponIndirimi <
                (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0)
                ? 0
                : order.itemTotal +
                  order.taxTotal +
                  shippingPrice -
                  (wallet?.coinTL ?? 0) -
                  (wallet?.bonusTL ?? 0) -
                  this.state.toplamKuponIndirimi
              : order.itemTotal +
                order.taxTotal +
                shippingPrice -
                this.state.toplamKuponIndirimi +
                (this.state.paymentMethod ===
                paymentType.DoorPayment
                  ? doorPaymentPrice
                  : 0)
            );

    return (
      <div className="container payment-padding">
        <div className="row payment-container">
          <div className="col-12 col-xl-4 my-2">
            {isDigitalCoin ? (
              <CoinItem coin={coin} />
            ) : (
              <div className={classNames("card", { "d-none": isDigitalCoin })}>
                <div className="card-header p-0">
                  <h5 className="fs-20 w-100 font-weight-bold color-484848 d-flex justify-content-between align-items-center">
                    Adres bilgileri
                  </h5>
                </div>
                <div className="card-body p-0">
                  <div
                    className={classNames({ "d-none": this.state.collapse0 })}
                  >
                    <DeliveryAddress
                      options={addressesOptions}
                      onAddClick={this.handleAddClick}
                      onEditClick={this.handleEditClick}
                      address={order.deliveryAddress}
                      onChange={this.deliveryAddressChange}
                      deleteAddress={this.deleteAddress}
                      key={order}
                      title="Teslimat Adresi Seçiniz"
                    />
                    {!isAllDigitalProduct && (
                      <ShippingOptions
                        shippingOptions={this.props.shippingOptions}
                        order={order}
                        selectedShippingOptions={this.state.shippingOption}
                        changeShipping={this.handleChangeShipping}
                        paymentMethod={this.state.paymentMethod}
                      />
                    )}
                    <div className="form-group form-check same-text m-0">
                      <input
                        type="checkbox"
                        id="deliveryCheckbox"
                        onChange={this.handleOnChange}
                        checked={this.state.isSame}
                      />
                      <label htmlFor="deliveryCheckbox">
                        Teslimat adresi ile fatura adresi aynı olsun
                      </label>
                    </div>
                    {this.state.isSame !== true && (
                      <DeliveryAddress
                        options={addressesOptions}
                        onAddClick={this.handleAddClick}
                        onEditClick={this.handleEditClick}
                        address={order.billAddress}
                        onChange={this.billAddressChange}
                        deleteAddress={this.deleteAddress}
                        title="Fatura Adresi"
                        key={order}
                      />
                    )}
                  </div>
                </div>
                <AddressForm
                  show={this.state.show}
                  onCloseClick={() => this.setState({ show: false })}
                  title={this.state.title}
                  address={this.state.address}
                  createAddress={this.createAddress}
                  updateAddress={this.updateAddress}
                />
              </div>
            )}

            {!isDigitalCoin && <div class="card mt-3">
              <div className="card-header p-0">
                <h5 className="fs-20 w-100 font-weight-bold color-484848 d-flex justify-content-between align-items-center">
                  İndirim Kodunuzu Giriniz
                </h5>
              </div>
              <div className="basketCodeWrap">
                <div className="disocuntCode mb-0" id="disocuntCodeDiv">
                  <input
                    type="text"
                    id="indirimInput"
                    maxlength="25"
                    value={this.state.discountCode}
                    onChange={(e) => this.setIndirimKodu(e.target.value)}
                    placeholder="İndirim kodu"
                  />
                  <button
                    className="btn btn-apply"
                    id="uygula"
                    onClick={() => {
                      this.clickApply();
                    }}
                  >
                    {this.state.couponIsReserved == true ? "Vazgeç" : "Uygula"}
                  </button>
                </div>
              </div>
            </div>}
          </div>
          {console.log("isDigitalCoin", isDigitalCoin)}
          <div className="col-12 col-xl-4 my-2">
            {isDigitalCoin ? (
              <div className="card">
                <div className="card-header">
                  <h5 className="fs-20 w-100 font-weight-bold color-484848 d-flex justify-content-between align-items-center">
                    Ödeme Yöntemleri
                  </h5>
                </div>
                <div className="card-body">
                  <Masterpass
                    startMPRegistration={this.state.startMPRegistration}
                    acceptSave={this.masterpassStateChange}
                    payment={this.state.payment}
                    payWithMasterpass={this.payWithMasterpass}
                    payAndSaveMasterpass={this.payAndSaveMasterpass}
                    mpRegistrationCompleted={this.mpRegistrationCompleted}
                    purchaseMasterpassStateChange={
                      this.purchaseMasterpassStateChange
                    }
                    getMasterpassTokens={this.props.getMasterpassTokens}
                    isSecure={this.state.isSecure}
                    isDigitalCoin={isDigitalCoin}
                    initiate3D={this.initiateMasterpass3D}
                    secure3DToken={this.state.secure3DToken}
                    cancelMpProcess={this.cancelMpProcess}
                    saveAfter3D={this.state.saveAfter3D}
                    saveMasterpass={this.state.saveMasterpass}
                    bankName={this.state.bankName}
                    paymentMethod={this.state.paymentMethod}
                    handleCreditCardChange={this.handleCreditCardChange}
                    card={this.state.card}
                    show3DCheckbox={
                      this.state.paymentMethod === paymentType.CreditCard ||
                      (this.state.paymentMethod === paymentType.DoorPayment &&
                        (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0) <
                          order.itemTotal + order.taxTotal + shippingPrice)
                    }
                    toggle3D={this.toggle3D}
                    cardInfo={this.state.cardInfo}
                    installment={this.state.installment}
                    setInstallment={(i, r) => this.setInstallment(i, r)}
                    total={coin ? coin.price.toFixed(2) : 0}
                    shippingPrice={shippingPrice}
                  />
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-header flex-column p-0">
                  <h5 className="fs-20 w-100 font-weight-600 color-484848 d-flex justify-content-between align-items-center">
                    Ödeme Yöntemleri
                  </h5>
                  <div
                    className={classNames("payment-tab-menu flex-column", {
                      "d-none": this.state.collapse1,
                    })}
                  >
                    <div className="payment-tab-header">
                      {paymentMethods.map((item) =>
                        (item.value === 3 && !showDoorPayment) ||
                        (hasDigitalProduct &&
                          (item.value === 2 || item.value === 3)) ? null : (
                          <SelectButton
                            {...item}
                            selectedValue={this.state.paymentMethod}
                            onSelect={(value) => {
                              this.setState({
                                paymentMethod: value,
                                purchaseMasterpass: false,
                                masterpassBin: "",
                                saveMasterpass: false,
                                payJetonAndCard: false,
                              });
                            }}
                            key={item.value}
                          />
                        )
                      )}
                    </div>
                    <div className="payment-tab-content">
                      {this.state.paymentMethod === paymentType.CreditCard && (
                        <div>
                          <Masterpass
                            startMPRegistration={this.state.startMPRegistration}
                            acceptSave={this.masterpassStateChange}
                            payment={this.state.payment}
                            payWithMasterpass={this.payWithMasterpass}
                            payAndSaveMasterpass={this.payAndSaveMasterpass}
                            mpRegistrationCompleted={
                              this.mpRegistrationCompleted
                            }
                            purchaseMasterpassStateChange={
                              this.purchaseMasterpassStateChange
                            }
                            getMasterpassTokens={this.props.getMasterpassTokens}
                            isSecure={this.state.isSecure}
                            initiate3D={this.initiateMasterpass3D}
                            secure3DToken={this.state.secure3DToken}
                            cancelMpProcess={this.cancelMpProcess}
                            saveAfter3D={this.state.saveAfter3D}
                            saveMasterpass={this.state.saveMasterpass}
                            bankName={this.state.bankName}
                            paymentMethod={this.state.paymentMethod}
                            handleCreditCardChange={this.handleCreditCardChange}
                            card={this.state.card}
                            show3DCheckbox={
                              this.state.paymentMethod ===
                                paymentType.CreditCard ||
                              (this.state.paymentMethod ===
                                paymentType.DoorPayment &&
                                (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0) <
                                  order.itemTotal +
                                    order.taxTotal +
                                    shippingPrice)
                            }
                            toggle3D={this.toggle3D}
                            cardInfo={this.state.cardInfo}
                            installment={this.state.installment}
                            setInstallment={(i, r) => this.setInstallment(i, r)}
                            total={total}
                            shippingPrice={shippingPrice}
                          />

                          {/* burası garanti pay
                                                    <div className="row">
                                                        <button name="garantiPayButton" type="button" className="btn btn-outline-success mt-3 ml-3">
                                                            <div className="d-flex flex-row justify-content-center align-items-center px-3">
                                                                <img
                                                                    src={garantiPayLogo}
                                                                    className={classNames({
                                                                        "rotate-180": this.state.collapse0
                                                                    })}
                                                                    style={{ height: '30px' }}
                                                                    alt="Garanti Pay ile öde"
                                                                />
                                                                <span className="ml-2">
                                                                    ile öde
                                                                </span>
                                                            </div>
                                                        </button>
                                                    </div> */}
                        </div>
                      )}
                      {this.state.paymentMethod ===
                        paymentType.BankTransfer && (
                        <div>
                          <div className="bank-info-container">
                            <p>
                              <strong>Hesap Adı:</strong>Doğru Kaynak Dağıtım
                              Teknoloji A.Ş.
                            </p>
                            <p>
                              <strong>Banka:</strong>
                              {" Türkiye İş Bankası "}
                            </p>
                            <p>
                              <strong>Şube Adı:</strong>
                              {" Otosanayi / İstanbul "}
                            </p>
                            <p>
                              <strong>Hesap No:</strong>
                              {" 1107-1025751"}
                            </p>

                            <p>
                              <strong>IBAN:</strong>
                              {" TR62 0006 4000 0011 1071 0257 51"}
                            </p>
                          </div>
                          <div className="info-card z-index-2">
                            <div className="card-body fs-12 color-202124 font-weight-500">
                              Havale/EFT gönderiminizde; “ALICI” kısmına Doğru
                              Kaynak A.Ş. ve “AÇIKLAMA” kısmına sipariş
                              numaranızı yazmayı unutmayın.
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.paymentMethod === paymentType.DoorPayment &&
                        selectedShippingOption &&
                        selectedShippingOption.doorPaymentMinimum >= 0 &&
                        selectedShippingOption.doorPaymentMinimum <=
                          order.itemTotal + order.taxTotal && (
                          <div className="doorpayment-container">
                            <h6 className="fs-12 font-weight-bold color-202124">
                              Kapıda nakit para tahsilat bedeli :
                            </h6>
                            <p className="fs-16 color-f34854 font-weight-bold  z-index-2 ">
                              {formatPrice2(doorPaymentPrice.toFixed(2))}₺
                              &nbsp;
                              <span className="color-545454 fs-12 font-weight-500">
                                (Kargo ücreti değildir.)
                              </span>
                            </p>

                            <div className="info-card z-index-2">
                              <div className="card-body fs-12 color-484848 font-weight-500">
                                <p className="m-0">
                                  Kapıda ödeme yöntemiyle verilen siparişlerde
                                  kargo teslim süresi 2-3 iş günü sürmektedir.
                                  Toplam sipariş tutarınızı kargo yetkilisine
                                  ödeyerek ürünüzü teslim alabilirsiniz.Kapıda
                                  ödeme yönteminde kapıda nakit para tahsilat
                                  ücreti olan{" "}
                                  {formatPrice2(shippingPrice.toFixed(2))} TL
                                  siparişinize eklenecektir.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      {this.state.paymentMethod === paymentType.Coin && (
                        <div>
                          <p className="fs-16 color-f34854 font-weight-bold z-index-2">
                            <strong className="fs-12 color-202124">
                              Toplam:&nbsp;
                            </strong>
                            {` ${formatPrice2(
                              (
                                (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0)
                              ).toFixed(2)
                            )} ₺ `}
                            <span className="fs-12 font-weight-500 color-545454">
                              jetonunuz vardır.
                            </span>
                          </p>

                          <div className="form-group form-check z-index-1  ml-0">
                            <input
                              type="checkbox"
                              id="check1"
                              key={this.state.payJetonAndCard}
                              onChange={() =>
                                this.setState({
                                  payJetonAndCard: !this.state.payJetonAndCard,
                                  purchaseMasterpass: false,
                                  masterpassBin: "",
                                  saveMasterpass: false,
                                })
                              }
                              defaultChecked={this.state.payJetonAndCard}
                              value={this.state.payJetonAndCard}
                              style={{ margin: "-10px" }}
                            />
                            {order.itemTotal + order.taxTotal + shippingPrice - this.state.toplamKuponIndirimi >
                              (wallet?.coinTL ?? 0) +
                                (wallet?.bonusTL ?? 0) && (
                              <label htmlFor="check1">
                                {`Kredi kartı ile kalan ${formatPrice2(
                                  (
                                    order.itemTotal +
                                    order.taxTotal +
                                    shippingPrice -
                                    ((wallet?.coinTL ?? 0) +
                                      (wallet?.bonusTL ?? 0)) -
                                    (this.state.toplamKuponIndirimi || 0)
                                  ).toFixed(2)
                                )} TL'yi tamamlamak istiyorum.`}
                              </label>
                            )}
                          </div>
                          {this.state.payJetonAndCard ? (
                            <Masterpass
                              startMPRegistration={
                                this.state.startMPRegistration
                              }
                              acceptSave={this.masterpassStateChange}
                              payment={this.state.payment}
                              payWithMasterpass={this.payWithMasterpass}
                              payAndSaveMasterpass={this.payAndSaveMasterpass}
                              mpRegistrationCompleted={
                                this.mpRegistrationCompleted
                              }
                              purchaseMasterpassStateChange={
                                this.purchaseMasterpassStateChange
                              }
                              getMasterpassTokens={
                                this.props.getMasterpassTokens
                              }
                              isSecure={this.state.isSecure}
                              initiate3D={this.initiateMasterpass3D}
                              secure3DToken={this.state.secure3DToken}
                              cancelMpProcess={this.cancelMpProcess}
                              saveAfter3D={this.state.saveAfter3D}
                              saveMasterpass={this.state.saveMasterpass}
                              bankName={this.state.bankName}
                              paymentMethod={this.state.paymentMethod}
                              handleCreditCardChange={
                                this.handleCreditCardChange
                              }
                              card={this.state.card}
                              show3DCheckbox={
                                this.state.paymentMethod ===
                                  paymentType.CreditCard ||
                                (this.state.paymentMethod ===
                                  paymentType.Coin &&
                                  this.state.payJetonAndCard &&
                                  (wallet?.coinTL ?? 0) +
                                    (wallet?.bonusTL ?? 0) <
                                    order.itemTotal +
                                      order.taxTotal +
                                      shippingPrice)
                              }
                              toggle3D={this.toggle3D}
                              cardInfo={this.state.cardInfo}
                              installment={this.state.installment}
                              setInstallment={(i, r) =>
                                this.setInstallment(i, r)
                              }
                              total={totalAmount}
                              shippingPrice={shippingPrice}
                            />
                          ) : null}
                        </div>
                      )}
                      {this.state.paymentMethod === paymentType.BkmExpress && (
                        <div>
                          <div className="bkm-express-container">
                            <Icon icon="iBkm" />
                            <ul>
                              <li>
                                1- Ödeme işlemi için BKM Express’e
                                yönlendirileceksiniz.
                              </li>
                              <li>
                                2-İstediğiniz kartı ve ödeme şeklini seçerek
                                kolayca ödeme yapabilirsiniz.
                              </li>
                              <li>
                                3- Alışverişinizi tamamladıktan sonra otomatik
                                olarak Tonguç Mağaza’ya döneceksiniz.
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.paymentType !== "CreditCard" &&
              this.state.paymentType !== "CreditCard2" && <SearchStore />}
          </div>

          <div className="col-12 col-xl-3 my-2">
            <div className="card">
              <div className="card-header d-none d-sm-block p-0">
                <h5 className="fs-20 font-weight-bold color-202124 w-100 d-flex justify-content-between align-items-center">
                  Sipariş Özeti
                </h5>
              </div>

              <div className={classNames("card-body p-0")}>
                {!isDigitalCoin && (
                  <div className="aling-items-center d-none d-sm-flex text-primary font-weight-bold fs-18">
                    <span>Toplam:&nbsp;</span>
                    <span>
                      {formatPrice(((totalAmount - shippingPrice).applyInterest(this.state.installmentRate) + shippingPrice).toFixed(2))}
                    </span>
                  </div>
                )}

                {isDigitalCoin ? (
                  <div className="b-dedede p-3 my-2 fs-13 border-radius-8 ">
                    <b className="text-primary mt-2">
                      {`Toplam: ${
                        coin ? formatPrice2(coin.price.applyInterest(this.state.installmentRate).toFixed(2)) : 0
                      } ₺`}
                    </b>
                  </div>
                ) : (
                  <div className="total-details color-545454 font-weight-500 fs-16 py-2 my-3 fs-13 d-none d-sm-block">
                    <div className="mt-1 d-flex justify-content-between">
                      <span>Ürünler:</span>
                      <span className="color-202124">
                        {formatPrice(
                          order.itemTotal
                            .toFixed(2)
                        )}
                      </span>
                    </div>
                    <div className="mt-1 d-flex justify-content-between">
                      <span>KDV:</span>
                      <span className="color-202124">
                        {formatPrice(
                          order.taxTotal
                            .toFixed(2)
                        )}
                      </span>
                    </div>
                    <div className="mt-1 d-flex justify-content-between">
                      <span>Kupon İndirimi:</span>
                      <span className="color-202124">
                        {formatPrice(
                          this.state.toplamKuponIndirimi
                            .toFixed(2)
                        )}
                      </span>
                    </div>

                    <div className="mt-1 d-flex justify-content-between">
                      <span> Kargo: </span>
                      <span className="color-202124">
                        {formatPrice(
                          shippingPrice
                            .toFixed(2)
                        )}
                      </span>
                    </div>
                    <div className="mt-1 d-flex justify-content-between">
                      <span> Toplam Komisyon: </span>
                      <span className="color-202124">
                        {
                        formatPrice(parseFloat(totalAmount - shippingPrice).applyPureInterest(this.state.installmentRate).toFixed(2))
                      }</span>
                    </div>
                    {this.state.paymentMethod == paymentType.DoorPayment ? (
                      <div className="mt-1 d-flex justify-content-between">
                        <span> Kapıda Ödeme Bedeli: </span>
                        <span className="color-202124">
                          {formatPrice(
                            doorPaymentPrice
                              .toFixed(2)
                          )}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.paymentMethod === paymentType.Coin && (
                      <div className="mt-1 d-flex justify-content-between">
                        <span>Harcanacak Jeton:</span>
                        <span>
                          {formatPrice(
                            ((wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0) >
                            order.itemTotal + order.taxTotal + shippingPrice - this.state.toplamKuponIndirimi
                              ? order.itemTotal + order.taxTotal + shippingPrice - this.state.toplamKuponIndirimi
                              : (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0)
                            )
                              .toFixed(2)
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="contract-box form-group form-check w-100 fs-12 color-484848 pl-4">
                  <input
                    type="checkbox"
                    id="check2"
                    onChange={() =>
                      this.setState({ acceptTerms: !this.state.acceptTerms })
                    }
                    checked={this.state.acceptTerms}
                    style={{ margin: "0 -11px" }}
                  />
                  <label
                    htmlFor="check2"
                    className="fs-12"
                    style={{ lineHeight: "1rem", fontWeight: 500, margin: 0 }}
                  ></label>
                  <OrderContracts
                    contracts={filteredOrders}
                    shippingInfo={selectedShippingOption}
                  />
                  okudum ve onaylıyorum.
                </div>
                <Button
                  className={classNames(
                    "btn btn-primary btn-block border-radius-8 fs-16 font-weight-bold px-3 py-2 text-capitalize d-none d-sm-flex align-items-center justify-content-center",
                    {
                      disabled:
                        !loaded ||
                        (this.state.paymentMethod === paymentType.Coin &&
                          (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0) == 0),
                    }
                  )}
                  type="button"
                  onClick={(e) =>
                    !loaded ||
                    (this.state.paymentMethod === paymentType.Coin &&
                      (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0) == 0)
                      ? e.preventDefault()
                      : this.handlePayment()
                  }
                >
                  {!loaded || !this.state.token
                    ? "Yükleniyor..."
                    : this.state.paymentType === "CreditCard" ||
                      this.state.paymentType === "CreditCard2"
                    ? "Alışverişi Tamamla"
                    : "Sipariş Ver"}
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                <div class="mt-3 d-flex justify-content-center">
                  <Turnstile
                    sitekey={turnstileSiteKeys.Payment}
                    onLoad={(widgetId) => {
                      this.setState({ turnstileWidgetId: widgetId });
                    }}
                    onVerify={(token) => {
                      this.setState({ token: token });
                    }}
                    onExpire={() => {
                      window.turnstile.reset(this.state.turnstileWidgetId);
                    }}
                    onError={() => {
                      window.turnstile.reset(this.state.turnstileWidgetId);
                    }}
                    size="compact"
                    language="tr"
                  />
                </div>
                {!loaded && (
                  <span>
                    <small>
                      Sayfa yüklemesi uzun sürdüyse{" "}
                      <a href="#" onClick={() => window.location.reload(true)}>
                        tıklayınız.
                      </a>
                    </small>
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="shopping-total-mobile-container">
            <div
              className={`shopping-total-mobile d-flex d-sm-none ${
                this.state.showPriceDetail ? "show" : ""
              }`}
            >
              <div className="shopping-mobile-detail">
                <span>Sipariş Özeti</span>
                <div
                  className="d-flex align-items-center"
                  onClick={() => this.toggleShow()}
                >
                  {formatPrice(
                        (this.state.paymentMethod === paymentType.Coin
                          ? order.itemTotal +
                              order.taxTotal +
                              shippingPrice -
                              this.state.toplamKuponIndirimi <
                            (wallet?.coinTL ?? 0) + (wallet?.bonusTL ?? 0)
                            ? 0
                            : order.itemTotal +
                              order.taxTotal +
                              shippingPrice -
                              (wallet?.coinTL ?? 0) - (wallet?.bonusTL ?? 0) -
                              this.state.toplamKuponIndirimi
                          : order.itemTotal +
                            order.taxTotal +
                            shippingPrice -
                            this.state.toplamKuponIndirimi +
                            (this.state.paymentMethod ===
                            paymentType.DoorPayment
                              ? doorPaymentPrice
                              : 0)
                        )
                          .toFixed(2)
                      )}
                  <div className="icon-box">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div>
              <ShoppingButton
                handleClick={(e) =>
                  !loaded ? e.preventDefault() : this.handlePayment()
                }
              />
            </div>
            <div
              className={`shopping-mobile-sub-detail ${
                this.state.showPriceDetail ? "show" : ""
              }`}
            >
              <div className="shopping-mobile-item">
                <div className="color-545454">Ürünler:</div>
                <span className="color-202124">
                  {formatPrice(
                    order.itemTotal
                    .toFixed(2)
                  )}
                  ₺
                </span>
              </div>
              <div className="shopping-mobile-item">
                <div className="color-545454">KDV:</div>
                <span className="color-202124">
                  {formatPrice(
                    order.taxTotal
                    .toFixed(2)
                  )}
                  ₺
                </span>
              </div>
              <div className="shopping-mobile-item">
                <div className="color-545454">Kupon İndirimi:</div>
                <span className="color-202124">
                  {formatPrice(
                    this.state.toplamKuponIndirimi
                    .toFixed(2)
                  )}
                  ₺
                </span>
              </div>
              <div className="shopping-mobile-item">
                <div className="color-545454">Kargo:</div>
                <span className="color-545454">
                  {formatPrice(
                    shippingPrice
                      .toFixed(2)
                  )}{" "}
                  ₺
                </span>
              </div>
            </div>
          </div>

          <Modal
            className="loginModal modal-container non-modal-height"
            show={showGarantiPay}
          >
            <div className="modal-header bt-dedede:50">
              <h5
                className="modal-title font-weight-600 color-484848"
                style={{ fontSize: 20 }}
              >
                Güvenli Ödeme
              </h5>
            </div>
            <div className="modal-body"></div>
          </Modal>
          {this.state.show3DSecure && (
            <PaymentModal
              show={true}
              orderId={orderId}
              order={order}
              payment={this.state.payment}
              innerHtml={this.state.innerHtml}
              onCloseClick={() => this.setState({ show3DSecure: false })}
              onSuccess={this.success}
              productType={match.params.type}
            />
          )}
          {this.state.masterpass3D && (
            <Masterpass3D
              show={true}
              closeImplicitly={this.closeMPModalImplicitly}
              closeExplicitly={this.closeMPModalExplicitly}
              URL3D={this.state.masterpass3DURL}
              onSuccess={this.on3DSuccess}
            />
          )}
        </div>
        <div id="payment-dropin"></div>
      </div>
    );
  }
}

Payment.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  coins: ProductReducer.coins,
  addresses: AccountReducer.addresses,
  shippingOptions: AccountReducer.shippingOptions,
  order: AccountReducer.order,
  wallet: ProductReducer.wallet,
  user: AccountReducer.user,
  stores: ProductReducer.stores,
  contracts: AccountReducer.contracts,
  taxonomies: ProductReducer.taxonomies,
  products: ProductReducer.allProducts,
});

const mapDispatchToProps = {
  fetchPayment,
  getContracts,
  getMasterpassTokens,
  getCoins,
  getAddresses,
  getShippingOptions,
  getOrder,
  createAddress,
  updateAddress,
  updateOrderAddresses,
  getCardInfo,
  pay,
  indirimUygula,
  indirimVazgec,
  payCoin,
  getStores,
  updateMasterpassNumber,
  pageVisited,
  deleteAddress,
  payBkm,
  deleteAllItemInBasket,
  // getTaxonomies
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
